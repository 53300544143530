import { Accordion } from '@uss/react-components';
import { Button } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import LabeledText from 'components/Typography/LabeledText';
import { useNavigate } from 'react-router-dom';

export const PositionGeneralDetail = () => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('edit');
  };

  const PayrollDetailsBody = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 w-full gap-2 my-2">
        <LabeledText label="Standard Hourly Rate">24:00</LabeledText>
        <LabeledText label="Converted Base Rate">9:50</LabeledText>
        <LabeledText label="Starter Standard Rate">24:00</LabeledText>
        <LabeledText label="Starter Converted Base Rate">9:50</LabeledText>
        <LabeledText label="Division">Recycle Raw Mat&apos;l</LabeledText>
        <LabeledText label="Department">1</LabeledText>
        <LabeledText label="OLD Rate">8</LabeledText>
        <LabeledText label="Default Start Time">8</LabeledText>
        <LabeledText label="Standard Shift Length">8</LabeledText>
        <LabeledText label="Buddy Releif">18</LabeledText>
        <LabeledText label="Calc Group">70</LabeledText>
        <LabeledText label="Incentive Plan Type">41</LabeledText>
        <LabeledText label="App Code">18</LabeledText>
        <LabeledText label="Wash Up Indicator">Yes</LabeledText>
        <LabeledText label="Auto OM Code">Yes</LabeledText>
        <LabeledText label="Service Bonus Pool">01</LabeledText>
      </div>
    );
  };

  const PiAuditDetailsBody = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-5 w-full gap-2 my-2 flex-nowrap">
        <LabeledText label="Position Type">Production</LabeledText>
        <LabeledText label="Crew Leader">Yes</LabeledText>
        <LabeledText label="Discipline">Union P&M</LabeledText>
        <LabeledText label="Division">Iron Producing</LabeledText>
        <LabeledText label="Department">Recycle-Raw Mat&apos;l</LabeledText>
        <LabeledText label="DRO Code">
          RMS-Recycle And Raw Mat&apos;l
        </LabeledText>
        <LabeledText label="Seniority Unit">GW Unit 05</LabeledText>
        <LabeledText label="LOP">Common</LabeledText>
        <LabeledText label="Suffix">A1</LabeledText>
        <LabeledText label="Direct">10</LabeledText>
        <LabeledText label="Support">10</LabeledText>
        <LabeledText label="Authorized">10</LabeledText>
        <LabeledText label="Assignment Group">
          Assistant Oven Heater
        </LabeledText>
        <LabeledText label="Change Reason" lineClamp={3}>
          Eliminated qty 2 Coke Shaker for labor efficiency project 4/10/2018
        </LabeledText>
        <LabeledText label="Last Updated By">--</LabeledText>
        <LabeledText label="Last Updated On">--</LabeledText>
      </div>
    );
  };

  return (
    <Page>
      <Page.Section bg="gray">
        <div className="bg-white pt-4 sm:bg-white rounded-md mb-2 pb-4 px-6">
          <h2 className="font-semibold text-lg">012200</h2>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-2 my-2">
            <LabeledText label="Position Name">Updox Util Shake</LabeledText>
            <LabeledText label="Payroll Location">A10</LabeledText>
            <LabeledText label="Bargaining Unit">01</LabeledText>
            <LabeledText label="LG">1</LabeledText>
            <LabeledText label="Job Desc">Maintennace Technician</LabeledText>
            <LabeledText label="Craft Code">46</LabeledText>
            <LabeledText label="Rate Code">01 Craft Position</LabeledText>
            <LabeledText label="Effective Date">Apr 10, 2020</LabeledText>
            <LabeledText label="End Date">--</LabeledText>
            <LabeledText label="Label">46</LabeledText>
          </div>
          <Accordion
            variant="default"
            className=" bg-white border-t-2 border-gray-400 md:border-none"
          >
            <Accordion.Button variant="default">
              <h2 className="font-semibold text-lg ml-[-16px]">
                Payroll Details
              </h2>
            </Accordion.Button>
            <Accordion.Panel
              variant="default"
              className="flex flex-row flex-wrap"
            >
              <PayrollDetailsBody />
            </Accordion.Panel>
          </Accordion>
          <div className="border-t border-gray-400 md:border-none" />
          <Accordion variant="default" className="bg-white">
            <Accordion.Button variant="default">
              <h2 className="font-semibold text-lg ml-[-16px]">
                PI & Audit Details
              </h2>
            </Accordion.Button>
            <Accordion.Panel
              variant="default"
              className="flex flex-row flex-wrap"
            >
              <PiAuditDetailsBody />
            </Accordion.Panel>
          </Accordion>
        </div>
        <div className="flex flex-col md:flex-row md:justify-end gap-4 mt-4">
          <Button variant="outline">Copy</Button>
          <Button variant="filled" onPress={handleEdit}>
            Edit
          </Button>
        </div>
      </Page.Section>
    </Page>
  );
};
