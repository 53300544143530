import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { useModelDetails } from 'features/model-listing/api/queries';
import { ModelDetailTab } from 'features/model-listing/components/ModelDetails/ModelDetailTab';
import { useParams } from 'react-router-dom';
import ComingSoon from 'views/ComingSoon';
import { NavLink } from 'react-router-dom';
import { Detailscard } from 'features/model-listing/components/ModelDetails/ModelDetailsCard';
import { WeekCardLoader } from 'features/model-listing/components/ModelDetails/WeekCardLoader';
import CardsLoader from 'components/Loaders/CardsLoader';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { useEffect } from 'react';
import StickyButton from 'components/StickyWrapper/StickyButton';
import StickyWrapper, {
  StickyStoreProvider,
} from 'components/StickyWrapper/StickyWrapper';

export const ModelDetail = () => {
  const { id = '' } = useParams<'id'>();
  const store = useModelDetailStore();

  const { data, status } = useModelDetails(id, store.params);
  useEffect(() => {
    data && store.updateModelDetail(data);
  }, [data, store]);
  const tabLinks = [
    {
      label: 'Details',
      element: (
        <>
          {status === 'success' ? (
            <ModelDetailTab data={data} />
          ) : (
            <WeekCardLoader
              numberOfWeeks={9}
              numberOfCrewRotations={3}
              numberOfGridCols={3}
            />
          )}
        </>
      ),
    },
    {
      label: 'View Usage',
      element: <ComingSoon />,
    },
    {
      label: 'History',
      element: <ComingSoon />,
    },
  ];
  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="bc-1" to="/scheduling">
            Scheduling
          </NavLink>,
          <NavLink key="bc-2" to="/non-union-scheduling/modeling">
            Modeling
          </NavLink>,
        ]}
        heading={'Model Details'}
      >
        <StickyStoreProvider>
          <StickyWrapper
            wrapperElement="section"
            className="px-5 pb-4 last-of-type:pb-0 top-[71px]"
          >
            <div className="flex gap-5 pb-2 items-center">
              <div className="flex gap-2 flex-grow items-center">
                <StickyButton
                  enabledText="Unpin Header"
                  disabledText="Pin Header"
                />
              </div>
            </div>

            {status === 'success' ? (
              <Detailscard data={data} />
            ) : (
              <CardsLoader variant="row-grid" count={1} hasAvatar />
            )}
          </StickyWrapper>
        </StickyStoreProvider>
        <Page.Section>
          <Tabs tabLinks={tabLinks} />
        </Page.Section>
      </Page>
    </>
  );
};
