import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { NavLink } from 'react-router-dom';

import { Badge, UnstyledLink } from '@uss/react-core';
import { getIncentiveStatusColors } from 'features/Incentive/utility/getIncentiveStatusColor';
import { getIncentiveStatusText } from 'features/Incentive/utility/getIncentiveStatusText';
import { IncentiveList } from 'features/Incentive/types/types';

interface IncentiveCardProps {
  incentiveListItem: IncentiveList;
}
export const IncentiveCard = ({ incentiveListItem }: IncentiveCardProps) => {
  return (
    <>
      <Card>
        <UnstyledLink
          as={NavLink}
          key={incentiveListItem.id}
          to={`${incentiveListItem.id}`}
          className="block h-full"
        >
          <Card.Body>
            <div className="sm:flex items-center w-full h-full ">
              <div className="flex flex-wrap lg:flex-nowrap w-full lg:w-full lg:pl-1 lg:pr-10">
                <div
                  className="flex w-full lg:w-5/12 lg:border-b-0 justify-between
                  lg:border-r-2 border-gray-200  lg:pb-0  lg:mb-0 pr-4 mr-4"
                >
                  <div className="flex flex-col text-md font-bold text-gray-700 py-1 mb-1 text-wrap">
                    {incentiveListItem.calcGroup}
                    <span className="text-sm text-gray-500 font-normal">
                      {incentiveListItem.calcGroupDescription}
                    </span>
                  </div>
                  <div className="flex justify-center gap-2 py-1">
                    <Badge
                      label={getIncentiveStatusText(
                        incentiveListItem.incentiveStatus
                      )}
                      color={getIncentiveStatusColors(
                        incentiveListItem.incentiveStatus
                      )}
                    />
                  </div>
                </div>
                <LabeledText
                  className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0 "
                  label="Payroll Location"
                  lineClamp={2}
                >
                  {incentiveListItem.payrollLocationDesc}
                </LabeledText>
                <div className="flex flex-row gap-14 mt-3 lg:mt-0">
                  <LabeledText
                    label="Dollar Code"
                    lineClamp={1}
                    className="w-1/2 lg:w-3/12 mb-2 last-of-type:mb-0 lg:mb-0"
                  >
                    {incentiveListItem.dollarCode}
                  </LabeledText>
                  <LabeledText
                    label="Application Code"
                    lineClamp={1}
                    className="w-1/2 lg:w-4/12 mb-2 last-of-type:mb-0 lg:mb-0"
                  >
                    {incentiveListItem.applicationCode}
                  </LabeledText>
                </div>
              </div>
            </div>
          </Card.Body>
        </UnstyledLink>
      </Card>
    </>
  );
};
