import { Button } from '@uss/react-components';
import useModal from 'components/Modals/use-modal';
import React, { useState } from 'react';
import { BsCheck2, BsX, BsPencil, BsCalendar2Range } from 'react-icons/bs';
import {
  ActionType,
  SetAllActiveType,
  TIMECARD_STATUS,
  SelectedEmployee,
} from 'features/timecards/types';
import { useNavigate } from 'react-router-dom';
import ApplyModelDrawer from 'features/model-listing/components/ApplyModel/ApplyModelDrawer';
import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { useProcessStatus } from 'services/api/common';
interface CrewEmployeeTableActionsProps {
  totalEmpsNum: number;
  selectedEmpsNum: number;
  showPayActions: boolean;
  setAllActive: SetAllActiveType;
  selectedEmployees: SelectedEmployee[];
  crewKey: string;
  handleUpdate: (status: ActionType) => void;
  handleCancel: () => void;
  payPeriodStatus: PayPeriodStatus | undefined;
}
const CrewEmployeeActions = ({
  totalEmpsNum,
  selectedEmpsNum,
  selectedEmployees,
  crewKey,
  showPayActions,
  setAllActive,
  handleUpdate,
  handleCancel,
  payPeriodStatus,
}: CrewEmployeeTableActionsProps) => {
  const modal = useModal();
  const navigate = useNavigate();
  const [isOpenApplyModel, setOpenApplyModelDrawer] = useState(false);
  //Checking for Scheduling screen
  const state = useNonUnionStore();
  const feature = state.feature;
  const isSchedulingDetails = feature === 'scheduling';

  //Get status of backend Asynch process using porcess ID
  const { data: processStatus } = useProcessStatus(
    state.processId ? state.processId : ''
  );

  const handleApprove = () => {
    modal.openModal({
      type: 'regular',
      title: 'Approve Timecard',
      children: (
        <MassApproveModal
          count={selectedEmployees.length}
          crew={crewKey ? crewKey : ''}
        />
      ),
      labels: { confirm: 'Approve', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleUpdate(TIMECARD_STATUS.APPROVED),
    });
  };
  const handleUnapprove = () => {
    modal.openModal({
      type: 'regular',
      title: 'Unapprove Timecard',
      children: (
        <MassUnapproveModal
          count={selectedEmployees.length}
          crew={crewKey ? crewKey : ''}
        />
      ),
      labels: { confirm: 'Unapprove', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleUpdate(TIMECARD_STATUS.UNAPPROVED),
    });
  };
  const handleVerify = () => {
    modal.openModal({
      type: 'regular',
      title: 'Verify Timecard',
      children: (
        <MassVerifyModal
          count={selectedEmployees.length}
          crew={crewKey ? crewKey : ''}
        />
      ),
      labels: { confirm: 'Verify', cancel: 'Cancel' },
      onCancel: () => modal.closeModal(),
      onConfirm: () => handleUpdate(TIMECARD_STATUS.VERIFIED),
    });
  };

  const onCancelClick = () => {
    setAllActive(false);
    handleCancel();
  };
  const onEditClick = () => {
    if (selectedEmployees.length > 0) {
      navigate(
        `/non-union-${
          showPayActions ? 'pay' : 'scheduling'
        }/mass-edit/employee/${selectedEmployees[0].ussId.toString()}`
      );
    }
  };
  return (
    <div>
      {/* Employee Action Bar Strats */}
      <div
        className="flex flex-wrap p-2 bg-accent2-500 text-white h-auto sm:h-14 items-center rounded-sm text-base"
        data-testid="timecard-actions-container"
      >
        <div className="text-center sm:text-left w-full sm:w-1/5">
          {selectedEmpsNum}/{totalEmpsNum} Records Selected
        </div>
        <div className="flex flex-wrap justify-end w-full sm:w-4/5">
          <>
            <Button
              className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
              variant="text"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
            {payPeriodStatus !== PAY_PERIOD_STATUS.NOT_OPEN && (
              <Button
                className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
                variant="text"
                startIcon={<BsPencil className="text-white" size="0.75em" />}
                onClick={onEditClick}
              >
                Edit Detail
              </Button>
            )}
            {showPayActions ? (
              <>
                <Button
                  className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
                  variant="text"
                  startIcon={<BsX className="text-white" />}
                  onClick={() => handleUnapprove()}
                >
                  Unapprove
                </Button>
                <Button
                  className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
                  variant="text"
                  startIcon={<BsCheck2 className="text-white" />}
                  onClick={() => handleApprove()}
                >
                  Approve
                </Button>
              </>
            ) : (
              <>
                {payPeriodStatus !== PAY_PERIOD_STATUS.NOT_OPEN && (
                  <Button
                    className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto"
                    variant="text"
                    startIcon={<BsCheck2 className="text-white" />}
                    onClick={() => handleVerify()}
                  >
                    Verify
                  </Button>
                )}
              </>
            )}
            {payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN &&
              isSchedulingDetails && (
                <Button
                  variant="text"
                  onClick={() => setOpenApplyModelDrawer(true)}
                  className="mr-5 w-full mt-3 sm:mt-0 sm:w-auto text-white"
                  disabled={processStatus === 'in_progress'}
                >
                  <BsCalendar2Range size="1rem" />
                  <span className="ml-2">Apply Model</span>
                </Button>
              )}
          </>
        </div>
      </div>
      {/* Employee Action Bar Ends */}
      <ApplyModelDrawer
        applyFeature="crew-listing"
        openDrawer={isOpenApplyModel}
        setOpenDrawer={setOpenApplyModelDrawer}
        fnClearCheckbox={handleCancel}
      />
    </div>
  );
};

interface ModalProps {
  count: number;
  crew: string;
}
const MassApproveModal = ({ count, crew }: ModalProps) => {
  return (
    <div>
      {`Are you sure you want to approve ${count} timecards for crew ${crew} ?`}
    </div>
  );
};
const MassUnapproveModal = ({ count, crew }: ModalProps) => {
  return (
    <div>
      {`Are you sure you want to Unapprove ${count} timecards for crew ${crew} ?`}
    </div>
  );
};
const MassVerifyModal = ({ count, crew }: ModalProps) => {
  return (
    <div>
      {`Are you sure you want to verify ${count} timecards for crew ${crew} ?`}
    </div>
  );
};

export default CrewEmployeeActions;
