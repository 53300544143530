import { Button } from '@uss/react-components';
import {
  EmployeeTimecard,
  TIMECARD_ACTION_BUTTON,
  TimecardActionButton,
  Timecard,
} from 'features/timecards/types';
import useModal from 'components/Modals/use-modal';
import { getActionModalProperties } from 'features/timecards/utilities/getActionModalProperties';
import { alertType } from 'components/Modals/ModalsContext';
import { useEmployeeTimecardWithoutCrew } from 'features/timecards/api/getTimecardDetails';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Modal, UnstyledButton } from '@uss/react-core';
import { BsX } from 'react-icons/bs';
interface PayActionsProps {
  buttonRef: React.MutableRefObject<TimecardActionButton>;
  handleUpdate: () => Promise<void>;
  payButtonsState: {
    canSaveApprove: boolean;
    canUnapprove: boolean;
    canOpenAdjustment: boolean;
    canSaveAdjustment: boolean;
    canSaveApproveAdjustment: boolean;
    canUnapproveAdjustment: boolean;
    isApproveDisabled: boolean;
  };
  isTimecardDisabled: boolean;
  employee: EmployeeTimecard;
  approveAuditalerts: alertType[];
}
type PayActionButtons = Exclude<
  TimecardActionButton,
  | typeof TIMECARD_ACTION_BUTTON.CREATE_TIMECARD
  | typeof TIMECARD_ACTION_BUTTON.SAVE
  | typeof TIMECARD_ACTION_BUTTON.SAVE_VERIFY
  | typeof TIMECARD_ACTION_BUTTON.CANCEL
>;
type Actions = Record<
  PayActionButtons,
  {
    isAllowed: boolean;
    title: string;
    actionType: PayActionButtons;
    isDisabled: boolean;
  }
>;
const PayActions = ({
  buttonRef,
  handleUpdate,
  payButtonsState,
  isTimecardDisabled,
  employee,
  approveAuditalerts,
}: PayActionsProps) => {
  const {
    canSaveApprove,
    canUnapprove,
    canOpenAdjustment,
    canSaveAdjustment,
    canSaveApproveAdjustment,
    canUnapproveAdjustment,
    isApproveDisabled,
  } = payButtonsState;

  const actions: Actions = {
    [TIMECARD_ACTION_BUTTON.SAVE_APPROVE]: {
      isAllowed: canSaveApprove,
      title: 'Save & Approve',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_APPROVE,
      isDisabled: isApproveDisabled || isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.UNAPPROVE]: {
      isAllowed: canUnapprove,
      title: 'Unapprove',
      actionType: TIMECARD_ACTION_BUTTON.UNAPPROVE,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS]: {
      isAllowed: canSaveAdjustment || canSaveApproveAdjustment,
      title: 'Save',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS]: {
      isAllowed: canSaveAdjustment || canSaveApproveAdjustment,
      title: 'Save & Approve Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS,
      isDisabled: !canSaveApproveAdjustment || isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS]: {
      isAllowed: canOpenAdjustment,
      title: 'Open For Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.OPEN_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
    [TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS]: {
      isAllowed: canUnapproveAdjustment,
      title: 'Unapprove Adjustment',
      actionType: TIMECARD_ACTION_BUTTON.UNAPPROVE_ADJUSTMENTS,
      isDisabled: isTimecardDisabled,
    },
  } as const;
  // created empty timecard row object for comparing with adjustment timecard row if original timecard row is empty
  const emptyTimecardRowObject: Timecard = {
    id: 'temp',
    date: '',
    elapsedTime: null,
    scheduled: {
      turn: '',
      hours: null,
    },
    worked: {
      turn: '',
      hours: null,
    },
    approved: {
      turn: '',
      hours: null,
    },
    scheduleVariance: {
      payCode: null,
      variance: 0,
    },
    paidHoursNotWorked: null,
    exceptionCode: null,
    exceptionCodeDescription: null,
    comments: '',
    blank: true,
    ptoDetails: null,
  };
  const [originalEmployeeTimecard, setOriginalEmployeeTimecard] =
    useState<EmployeeTimecard>();
  const [isNoChangeModalOpen, setIsOpenNoChangeModalOpen] =
    useState<boolean>(false);
  // Fetch Original timecard details Employee timecards
  const { data } = useEmployeeTimecardWithoutCrew(employee.ussId.toString(), {
    week: employee.week,
    adjustment: '0',
  });

  useEffect(() => {
    setOriginalEmployeeTimecard(data?.items[0]);
  }, [data]);

  const actionModal = useModal();

  const isNoChangeAdjustment = (employee: EmployeeTimecard) => {
    return employee.timecard.every((adjustTimecardRow) => {
      const originalTimecardRow =
        originalEmployeeTimecard?.timecard.find(
          (e) => e.date === adjustTimecardRow.date
        ) ?? emptyTimecardRowObject;

      return (
        _.isEqual(adjustTimecardRow.scheduled, originalTimecardRow.scheduled) &&
        _.isEqual(adjustTimecardRow.approved, originalTimecardRow.approved) &&
        _.isEqual(adjustTimecardRow.worked, originalTimecardRow.worked) &&
        _.isEqual(
          adjustTimecardRow.scheduleVariance,
          originalTimecardRow.scheduleVariance
        )
      );
    });
  };

  return (
    <>
      {(Object.keys(actions) as (keyof typeof actions)[]).map(
        (type) =>
          actions[type].isAllowed && (
            <Button
              onClick={() => {
                buttonRef.current = actions[type]
                  .actionType as TimecardActionButton;
                const { modalTitle, confirmButtonTitle, modalBodyText } =
                  getActionModalProperties(buttonRef);

                if (
                  actions[type].actionType ===
                    TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS &&
                  isNoChangeAdjustment(employee)
                ) {
                  setIsOpenNoChangeModalOpen(true);
                } else {
                  actionModal.openModal({
                    title: modalTitle,
                    type: 'regular',
                    size: 'md',
                    alert:
                      actions[type].actionType ===
                        TIMECARD_ACTION_BUTTON.SAVE_APPROVE_ADJUSTMENTS ||
                      actions[type].actionType ===
                        TIMECARD_ACTION_BUTTON.SAVE_APPROVE
                        ? approveAuditalerts
                        : [],
                    children: (
                      <div className="text-sm text-gray-700">
                        <p>{modalBodyText} </p>
                      </div>
                    ),

                    labels: { confirm: confirmButtonTitle, cancel: 'Cancel' },
                    onCancel: actionModal.closeModal,
                    onConfirm: async () => {
                      actionModal.closeModal();
                      await handleUpdate();
                    },
                  });
                }
              }}
              variant="primary"
              className="ml-0 mb-2 sm:mb-0 sm:ml-4 text-sm w-full sm:w-auto"
              type="submit"
              disabled={actions[type].isDisabled}
              key={type}
              data-testid={actions[type].actionType}
            >
              {actions[type].title}
            </Button>
          )
      )}
      <Modal
        ariaLabel={'Approve Adjustment'}
        isOpen={isNoChangeModalOpen}
        size="default"
      >
        <Modal.Header>
          <div className="flex flex-grow items-center ">
            <h2>Approve Adjustment</h2>
          </div>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setIsOpenNoChangeModalOpen(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          You must make one or more changes to Original timecard to be able to
          approve an adjustment.
        </Modal.Content>
        <Modal.Footer>
          <div className="flex gap-2">
            <Button
              variant={'primary'}
              onClick={() => setIsOpenNoChangeModalOpen(false)}
            >
              Ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default PayActions;
