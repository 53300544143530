import { useQuery } from '@tanstack/react-query';
import { OrgsOptions } from '../types';
import { orgList } from './api';

export const useOrgV2 = (options?: OrgsOptions) => {
  return useQuery({
    queryKey: ['org-list-v2', options],
    queryFn: () => orgList.get(options),
    refetchOnMount: true,
  });
};
