import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { Badge } from '@uss/react-core';
import { getIncentiveStatusColors } from 'features/Incentive/utility/getIncentiveStatusColor';
import { getIncentiveStatusText } from 'features/Incentive/utility/getIncentiveStatusText';
import { useParams } from 'react-router-dom';
import { useIncentiveDetails } from 'features/Incentive/api/queries';

export const IncentiveDetailsCard = () => {
  const { id = '' } = useParams<'id'>();
  const { data } = useIncentiveDetails(id);
  return (
    <Card>
      <Card.Body>
        {data && (
          <div className="flex items-center">
            <div className="flex flex-col sm:flex-wrap lg:flex-row justify-start w-full">
              <div className="flex flex-row items-center justify-between w-full lg:w-auto lg:gap-20">
                <div className="flex flex-col text-md font-bold text-gray-700">
                  {data.calcGroup}
                  <span className="text-sm text-gray-500 font-normal">
                    {data.calcGroupDescription}
                  </span>
                </div>
                <div className="flex items-start mb-4">
                  <Badge
                    label={getIncentiveStatusText(data.incentiveStatus)}
                    color={getIncentiveStatusColors(data.incentiveStatus)}
                  />
                </div>
              </div>
              <div className="w-full lg:w-auto border-t-2 lg:border-r-2 border-gray-300 lg:mx-4 my-4 lg:my-0" />
              <div className="flex flex-row justify-start lg:gap-10 gap-4">
                <LabeledText
                  className="w-1/2 lg:w-auto"
                  lineClamp={1}
                  label="Payroll Location"
                >
                  {data.payrollLocationDesc}
                </LabeledText>
                <LabeledText label="Dollar Code">{data.dollarCode}</LabeledText>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
