import { axios } from 'services/api/axios';

import { OrgList, OrgsOptions } from '../types';

export const orgList = {
  get: (options?: OrgsOptions): Promise<OrgList | undefined> => {
    return axios.get(`/v2/orgs`, {
      params: options,
    });
  },
};
