import {
  Button,
  DatePicker,
  IconButton,
  Select,
  Sidepane,
  TextField,
} from '@uss/react-core';
import { BsX } from 'react-icons/bs';

interface AddRateDrawerProps {
  openAddRateDrawer: boolean;
  setOpenAddRateDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddRateDrawer = ({
  openAddRateDrawer,
  setOpenAddRateDrawer,
}: AddRateDrawerProps) => {
  const handleClose = () => {
    setOpenAddRateDrawer(false);
  };

  return (
    <Sidepane
      ariaLabel="filter"
      isOpen={openAddRateDrawer}
      onOpenChange={handleClose}
      className="w-full sm:w-3/12 md:w-3/12"
    >
      <div className="flex flex-row justify-between mb-4">
        <h2>Add Contract Rates</h2>
        <div className="flex flex-row mt-[-8px] items-center text-primary-400">
          <IconButton onPress={handleClose}>
            <BsX />
          </IconButton>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <Select label="Payroll Location" placeholder="Select" />
        <Select label="Bargaining Unit" placeholder="Select" />
        <Select label="Labor Grade" placeholder="Select" />
        <TextField label="Standard Rate" type="text" placeholder="Enter Text" />
        <TextField
          label="Converted Base Rate"
          type="text"
          placeholder="Enter Text"
        />
        <TextField
          label="Starter Standard Rate"
          type="text"
          placeholder="Enter Text"
        />
        <TextField
          label="Starter Converted base Rate"
          type="text"
          placeholder="Enter Text"
        />
        <DatePicker label="Effective Date"></DatePicker>
      </div>
      <div className="flex flex-row gap-2 justify-end mt-4">
        <Button variant="outline"> Cancel</Button>
        <Button variant="filled"> Add</Button>
      </div>
    </Sidepane>
  );
};
