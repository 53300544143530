import {
  Avatar,
  Button,
  Checkbox,
  Drawer,
  Select,
} from '@uss/react-components';
import Alert from 'components/Alert/Alert';
import useModal from 'components/Modals/use-modal';
import { format, nextSaturday } from 'date-fns';
import useAllowedCrews from 'features/crew-maintenance/api/useAllowedCrews';
import { HandleMoveEmployee } from 'features/crew-maintenance/types';
import { Org, OrgSecurityRecord, OrgsOptions, ORG_TYPE } from 'features/org';
import { useEmployeeOrgs } from 'features/org/api';
import { useState } from 'react';
import { SortBy } from 'types/common';
import { getInitials } from 'utils';
import { avatarColor } from 'utils/avatarColors';

interface MoveEmployeeProps {
  isOpen: boolean;
  onClose: () => void;
  employee: OrgSecurityRecord;
  onMove: HandleMoveEmployee;
  currentCrew: Org;
}

const MoveEmployee = ({
  isOpen,
  onClose,
  employee,
  currentCrew,
  onMove,
}: MoveEmployeeProps) => {
  const [selectedOption, setSelectedOption] = useState<Org | null>(null);
  const [moveFutureSchedules, setMoveFutureSchedules] = useState(false);
  const modal = useModal();
  const crewParams: OrgsOptions = {
    sortBy: SortBy.CODE,
    type: ORG_TYPE.CREW,
  };
  if (currentCrew.location) {
    crewParams.location = currentCrew.location;
  }
  const { data: crews, status } = useEmployeeOrgs('me', crewParams);

  const handleCrewSelect = (value: Org) => {
    setSelectedOption(value);
  };
  const handleChange = () => {
    setMoveFutureSchedules(!moveFutureSchedules);
  };

  const { crewOptions } = useAllowedCrews(employee.ussId.toString());

  const fromCrew =
    employee.location &&
    employee.division &&
    employee.department &&
    employee.crew
      ? `${employee.location}-${employee.division}-${employee.department}-${employee.crew}`
      : 'Invalid Crew Key';

  const getCrewCode = (value: Org | null) => {
    return value
      ? `${value.location ?? ''}-${value.division ?? ''}-${
          value.department ?? ''
        }-${value.crew ?? ''}`
      : 'Loc - Div - Dept - Crew';
  };
  const clearAll = () => {
    setSelectedOption(null);
    getCrewCode(selectedOption);
  };

  const moveEnabled = employee.ussId && selectedOption;

  const confirmMove = (moveFutureSchedules: boolean) => {
    modal.openModal({
      title: 'Move Employee',
      type: 'danger',
      onConfirm: async () => {
        moveEnabled &&
          (await onMove(currentCrew, moveFutureSchedules, selectedOption.id));

        modal.closeModal();
        setMoveFutureSchedules(false);
        clearAll();
      },
      onCancel: () => modal.closeModal(),
      labels: {
        cancel: 'Cancel',
        confirm: 'Move',
      },
      children: <MoveModalMessage moveSchChecked={moveFutureSchedules} />,
    });
  };

  const weekStartDate = format(
    nextSaturday(new Date(format(new Date(), 'MMM dd, yyyy'))),
    'MMM dd, yyyy'
  );

  const MoveModalMessage = ({
    moveSchChecked,
  }: {
    moveSchChecked: boolean;
  }) => {
    return (
      <div>
        <p className="text-sm text-gray-700">
          Are you sure you want to move {employee.firstName}&nbsp;
          {employee.lastName} from crew {employee.crew} to crew&nbsp;
          {selectedOption?.crew}?
        </p>
        {!moveSchChecked && (
          <p className="text-sm text-gray-700 mt-4">
            This will also delete any future schedules for this employee for the
            old crew.
          </p>
        )}
        <p className="text-sm text-gray-700 pt-4  ">
          All future PTO will be moved from the old crew to the new crew.
        </p>
        {moveSchChecked && (
          <>
            <p className="text-sm text-gray-700 pt-4  ">
              This will also move any future schedules for this employee from
              the old crew to the new crew.
            </p>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <Drawer
        placement={window.innerWidth > 768 ? 'right' : 'bottom'}
        title="Move"
        isOpen={isOpen}
        onClose={(): void => {
          onClose();
        }}
        closeButton={window.innerWidth > 768 ? false : true}
        className="w-full sm:w-1/2 md:w-1/3 xl:w-1/4 2xl:w-1/5 md:top-0 top-18 md:rounded-t-none rounded-t-md"
      >
        <div className="flex-grow">
          {
            <div className="flex items-center w-full border-b  border-gray-300 mb-5 pb-4">
              <Avatar color={avatarColor(employee.ussId)} size="medium">
                {getInitials(employee.firstName, employee.lastName)}
              </Avatar>
              <div className="flex flex-col flex-wrap flex-grow ml-4">
                <p className="text-gray-700 text-lg font-semibold text-left line-clamp-1">
                  {employee.displayName ? employee.displayName : '-'}
                </p>
                <p className="text-gray-700 text-sm text-left">
                  {employee.ussId ? employee.ussId : '-'}
                </p>
              </div>
            </div>
          }
          <div className="flex flex-col">
            <span className="text-xs text-gray-700 font-normal">From Crew</span>
            <span className="text-gray-900 text-base font-semibold mb-5">
              {fromCrew}
            </span>

            <span className="text-xs text-gray-700 font-normal mb-0.5">
              To Crew
            </span>
            <Select
              selected={getCrewCode(selectedOption)}
              onSelect={handleCrewSelect}
            >
              <Select.Button label={getCrewCode(selectedOption)} />
              <Select.Options>
                {status === 'success' && crews.items.length !== 0 ? (
                  crewOptions
                    .filter((o) => o.location === employee.payrollLocation)
                    .map((item: Org, index: number) => (
                      <Select.Option truncate key={index + 1} value={item}>
                        <span>{getCrewCode(item)}</span>
                      </Select.Option>
                    ))
                ) : (
                  <span>No Crews available</span>
                )}
              </Select.Options>
            </Select>

            <div className="mt-5">
              <Checkbox
                id="move-future-schedules"
                name="shifts"
                label="Move Future Schedules"
                checked={moveFutureSchedules}
                onChange={handleChange}
              />
              <Alert variant="info">
                <p>
                  {' '}
                  Move will be effective in upcoming pay period starting
                  <b>{' ' + weekStartDate}.</b>
                  <br />
                  All future PTO will be moved as well.
                </p>
                <p>For current pay period, use Add Temp Employee.</p>
              </Alert>
            </div>
          </div>
        </div>
        <Drawer.Controls>
          <Button
            className="md:mb-0 mb-2 w-full"
            variant="outline"
            onClick={(): void => {
              clearAll();
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="md:ml-4 w-full"
            disabled={!selectedOption}
            onClick={(e) => {
              confirmMove(moveFutureSchedules);
            }}
          >
            Move
          </Button>
        </Drawer.Controls>
      </Drawer>
    </>
  );
};

export default MoveEmployee;
