import { Person } from 'features/people';
import { Timecard, TimecardTotal } from 'features/timecards/types';
import isDateHoliday from '../utilities/checkDateHoliday';
import { HolidayList } from 'features/holidays';
import { minuteToDecimal } from 'utils/minuteToDecimal';
import { alertType } from 'components/Modals/ModalsContext';
import _ from 'lodash';
import { PaycodeList } from 'features/paycode';

/**
 * @description  For Integrated/Office locations only. On Timecard approval displays below alerts inside popup.
 
 * zeroApprovedPaidNotWorkedHrs - For Part Time employee only

 * holidayElapsedTime - When employee is Non Exempt (Class 3 and 6 (policy group: Non Exempt) and
   payroll location with gate system.

 * holidayWorkedHrs-When Employee is Non Exempt (Class 3 and 6 (policy group: Non Exempt) and
   payroll location does not have a gate system code OR Employee is Exempt (Class 4) in a SPECOT crew

 * unpaidLeaveHrs- when employee is Nonexempt and "Exempt-8HR in SPECOT crew"
 
 * @returns Alert messages array
 */

type checkPersonDetails = Pick<
  Person,
  'plantType' | 'policyGroup' | 'shiftAssignment' | 'payrollGroup'
>;

export function timecardApprovalAuditAlerts(
  person: checkPersonDetails | undefined,
  timecard: Timecard[],
  holidayList: HolidayList | undefined,
  totalHrs: TimecardTotal,
  gatesystemAvailable: 'y' | 'n' | null | undefined,
  crew: string | undefined | null,
  paycodeList?: PaycodeList,
  adjustmentVersion?: number
) {
  const softAuditAlerts: alertType[] = [];
  let finalSoftAuditAlerts: alertType[] = [];
  const shift = person?.shiftAssignment;
  const policyGroup = person?.policyGroup;
  const checkLocation =
    person?.plantType === 'office' || person?.plantType === 'integrated-mill';

  const isGateSystemAvailable = gatesystemAvailable === 'y';
  if (
    checkLocation &&
    zeroApprovedPaidNotWorkedHrs(
      totalHrs.approved,
      totalHrs.paidHoursNotWorked
    ) &&
    policyGroup === 'part-time'
  ) {
    softAuditAlerts.push({
      type: 'alert',
      message:
        'You are approving a record for this employee with 0 hours to be paid.',
    });
  }

  timecard.forEach((item) => {
    const isHoliday = isDateHoliday(item.date, holidayList);

    if (
      checkLocation &&
      unpaidLeaveHrs(item.scheduleVariance.payCode) &&
      (person.policyGroup === 'nonexempt' ||
        (policyGroup === 'exempt' && shift === '8hr'))
    ) {
      softAuditAlerts.push({
        type: 'alert',
        message:
          'You are approving a reduction to the employee’s base pay. Please confirm with your HR Representatives if an Oracle Status Change will be entered for this absence. If this entry is approved and there is an Oracle Status Change, the reduction will be duplicated.',
      });
    }

    if (!isHoliday) return false;
    if (
      checkLocation &&
      holidayElapsedTime(item.elapsedTime, item.approved.hours) &&
      policyGroup === 'nonexempt' &&
      isGateSystemAvailable
    ) {
      softAuditAlerts.push({
        type: 'alert',
        message:
          'You are approving to pay this employee Holiday Premium for more than the elapsed time on the day of the Holiday.',
      });
    }

    if (
      checkLocation &&
      holidayWorkedHrs(item.approved.hours) &&
      !isGateSystemAvailable &&
      (policyGroup === 'nonexempt' ||
        (policyGroup === 'exempt' && crew === 'SPECOT'))
    ) {
      softAuditAlerts.push({
        type: 'alert',
        message:
          'You are approving additional compensation for this employee by approving hours on an observed Holiday.',
      });
    }
  });

  // Show alert if 0 approved hour and no payable paycode is selected
  if (totalHrs.approved === 0) {
    const noPayApprovedTimecard = timecard.every((item) => {
      const selectedPaycode =
        item.scheduleVariance.payCode &&
        paycodeList?.items.find(
          (paycode) => paycode.code === item.scheduleVariance.payCode
        );

      return (
        ((item.approved.hours === null || item.approved.hours === 0) &&
          (item.scheduleVariance.payCode === null ||
            item.scheduleVariance.payCode === '')) ||
        selectedPaycode
      );
    });
    if (noPayApprovedTimecard) {
      softAuditAlerts.push({
        type: 'alert',
        message: ` ${
          adjustmentVersion === 0
            ? 'You are approving a timecard that will result in no pay to the employee.'
            : 'You are approving an adjustment that will result in no pay to the employee.'
        } `,
      });
    }
  }

  finalSoftAuditAlerts = _.uniqBy(softAuditAlerts, 'message');
  return finalSoftAuditAlerts;
}

/**
 * @description if total approved hours and paid not worked hours are 0,
 * @returns true
 */

export const zeroApprovedPaidNotWorkedHrs = (
  totalApprovedHrs: number | null,
  totalPaidNotWorkedHrs: number | null
): boolean => {
  return totalApprovedHrs === 0 && totalPaidNotWorkedHrs === 0 ? true : false;
};

/**
 * @description if the elapsed time on the observed holiday is less than the approved hours on the holiday
 * @returns true
 */

export const holidayElapsedTime = (
  elapsedTime: string | null,
  approvedHrs: number | null
): boolean => {
  const decimalElapsedTime = elapsedTime && minuteToDecimal(elapsedTime);

  return approvedHrs && decimalElapsedTime && decimalElapsedTime < approvedHrs
    ? true
    : false;
};

/**
 * @description  if the approved hours on an observed holiday are greater than 0
 * @returns true
 */

export const holidayWorkedHrs = (approvedHrs: number | null): boolean => {
  return approvedHrs && approvedHrs > 0 ? true : false;
};

/**
 * @description  If a timecard is with absentee code 'UL' or 'UF'
 * @returns true
 */
export const unpaidLeaveHrs = (
  scheduleVariancePayCode: string | null
): boolean => {
  return scheduleVariancePayCode === 'UL' || scheduleVariancePayCode === 'UF';
};
