import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Modal,
  Select,
  SelectOptions,
  TextAreaField,
  TextField,
  UnstyledButton,
} from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import {
  useAddIncentive,
  useInactiveIncentive,
  useReactivateIncentive,
} from 'features/Incentive/api/mutation';
import { useIncentiveDetails } from 'features/Incentive/api/queries';
import { AddIncentiveReqBodySchema } from 'features/Incentive/schemas/add-incentive-schemas';
import {
  AddIncentiveReqBody,
  Incentive,
  UpdateIncentiveReqBody,
} from 'features/Incentive/types/types';
import { useOrgV2 } from 'features/org/apiV2/queries';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { queryClient } from 'services/api/react-query';
export interface IncentiveFormType {
  incentiveId?: string;
}

export const IncentiveForm = ({ incentiveId }: IncentiveFormType) => {
  const { data } = useIncentiveDetails(incentiveId ?? '');
  const { roles } = useUserRoles();
  const navigate = useNavigate();

  const { data: payrollLocationList } = useOrgV2({ type: 'location' });

  const defaultValueData = {
    payrollLocationOrgId: '',
    calcGroupDescription: '',
    calcGroup: '',
    applicationCode: '',
    dollarCode: '',
    incentiveType: '',
    minAuditIncentive: 0,
    maxAuditIncentive: 0,
    minPayoutIncentive: 0,
    maxPayoutIncentive: 0,
    autoCode6Type: '',
    frozenIncentive: 0,
    responsibility1: '',
    responsibility2: '',
    comments: '',
  };
  const {
    control,

    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<Incentive>({
    mode: 'onSubmit',
    resolver: zodResolver(AddIncentiveReqBodySchema),
    defaultValues: defaultValueData,
  });
  const { mutateAsync: mutateAsyncInactive } = useInactiveIncentive(
    incentiveId ?? ''
  );
  const { mutateAsync: mutateAsyncReactivate } = useReactivateIncentive(
    incentiveId ?? ''
  );
  const { mutateAsync: mutateAsyncAddIncentive } = useAddIncentive();
  const [openAddmodal, setOpenAddModal] = useState<boolean>(false);

  const [openInactivateModal, setOpenInactivateModal] =
    useState<boolean>(false);
  const [addIncentiveComment, setAddIncentiveComment] = useState<string>('');

  const [inactivateComment, setInactivateComment] = useState<string>('');
  const [openReactivateModal, setOpenReactivateModal] =
    useState<boolean>(false);
  const [reactivateComment, setReactivateComment] = useState<string>('');
  const isAddButtonDisabled = !(
    dirtyFields.payrollLocationOrgId &&
    dirtyFields.calcGroup &&
    dirtyFields.calcGroupDescription &&
    dirtyFields.applicationCode
  );

  const dollarCodeOptions = [
    { id: '12', dollarCode: '4-MOTOR INSPR EXP' },
    { id: '13', dollarCode: '4-MOTOR INSPR' },
    { id: '14', dollarCode: '41 - MOTOR INSPR ' },
  ];

  const incentiveTypeOptions = [
    { id: '12', incentiveType: '' },
    { id: '13', incentiveType: '4-M' },
    { id: '14', incentiveType: '41 - MO ' },
  ];

  const autoCodeTypeOptions = [
    { id: '12', autoCodeType: '' },
    { id: '13', autoCodeType: 'v' },
    { id: '14', autoCodeType: 'MO ' },
  ];

  const responsibility1Options = [
    { id: '12', responsibility1: 'BR' },
    { id: '13', responsibility1: 'TR' },
    { id: '14', responsibility1: 'TT' },
  ];

  const responsibility2Options = [
    { id: '12', responsibility2: 'BR' },
    { id: '13', responsibility2: 'TM' },
    { id: '14', responsibility2: 'TT' },
  ];

  const handleInactiveIncentive = async () => {
    const payload = {
      ...data,
      comments: inactivateComment,
    };
    await mutateAsyncInactive(payload as UpdateIncentiveReqBody, {
      onSuccess: async () => {
        notify.success({
          message: `Calculation group ${data?.calcGroup ?? ''} - ${
            data?.calcGroupDescription ?? ''
          } is inactivated.`,
        });
        await queryClient.refetchQueries({
          queryKey: [`incentive-details`],
        });
        setOpenReactivateModal(false);
      },
    });
  };
  const handleCancel = () => {
    navigate('/incentives/incentive-table');
  };
  const handleReactiveIncentive = async () => {
    const payload = {
      ...data,
      comments: reactivateComment,
    };
    await mutateAsyncReactivate(payload as UpdateIncentiveReqBody, {
      onSuccess: async () => {
        notify.success({
          message: `Calculation group ${data?.calcGroup ?? ''} - ${
            data?.calcGroupDescription ?? ''
          } is Reactivated Successfully.`,
        });
        await queryClient.refetchQueries({
          queryKey: [`incentive-details`],
        });
        setOpenReactivateModal(false);
      },
    });
  };
  const isFieldDisabled = () => {
    return data?.incentiveStatus === 'Inactive';
  };

  const handleAdd = async () => {
    const payload = {
      ...getValues(),
      comments: addIncentiveComment,
    };

    await mutateAsyncAddIncentive(payload as AddIncentiveReqBody, {
      onSuccess: () => {
        handleCancel();
      },
    });
  };
  return (
    <>
      <form onSubmit={handleSubmit(() => handleAdd())}>
        <div className="flex items-center justify-center pb-6 bg-white pt-6 sm:bg-white rounded-md mb-2 px-3">
          <div className="grid lg:grid-cols-2 gap-4 sm:grid-cols-1">
            <Controller
              control={control}
              name="payrollLocationOrgId"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={payrollLocationList?.items}
                  className="mt-1.5"
                  label="Payroll Location"
                  placeholder="Select"
                  selectedKey={getValues().payrollLocationOrgId}
                  errorMessage={errors.payrollLocationOrgId?.message}
                  onSelectionChange={field.onChange}
                  isDisabled={
                    isFieldDisabled() || data?.incentiveStatus === 'Active'
                  }
                >
                  {(item) => (
                    <SelectOptions id={item.id}>
                      {item.name} - {item.description}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />
            <Controller
              control={control}
              name="calcGroupDescription"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Calculation Group Description"
                    type="text"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isInvalid={invalid}
                    minLength={1}
                    isRequired
                    isDisabled={
                      isFieldDisabled() || data?.incentiveStatus === 'Active'
                    }
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="calcGroup"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Calculation Group"
                    type="text"
                    id="ac-2"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="applicationCode"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Application Code"
                    type="text"
                    id="ac-3"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="dollarCode"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={dollarCodeOptions}
                  selectedKey={data?.dollarCode}
                  label="Dollar Code"
                  placeholder="Select"
                  onSelectionChange={field.onChange}
                  isDisabled={isFieldDisabled()}
                >
                  {(item) => (
                    <SelectOptions id={item.dollarCode}>
                      {item.dollarCode}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />

            <Controller
              control={control}
              name="incentiveType"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={incentiveTypeOptions}
                  label="Incentive Type"
                  placeholder="Select"
                  selectedKey={data?.incentiveType}
                  onSelectionChange={field.onChange}
                  isDisabled={isFieldDisabled()}
                >
                  {(item) => (
                    <SelectOptions id={item.incentiveType}>
                      {item.incentiveType}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />

            <Controller
              control={control}
              name="minAuditIncentive"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Minimum Audit Incentive"
                    type="text"
                    id="ac-4"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="maxAuditIncentive"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Maximum Audit Incentive"
                    type="text"
                    id="ac-5"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="minPayoutIncentive"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Minimum Payout Incentive"
                    type="text"
                    id="ac-5"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="maxPayoutIncentive"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label=" Maximum Payout Incentive"
                    type="text"
                    id="ac-5"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />

            <Controller
              control={control}
              name="autoCode6Type"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={autoCodeTypeOptions}
                  className="mt-1.5"
                  label="Auto Code 6 Type"
                  placeholder="Select"
                  selectedKey={data?.autoCode6Type}
                  onSelectionChange={field.onChange}
                  isDisabled={isFieldDisabled()}
                >
                  {(item) => (
                    <SelectOptions id={item.autoCodeType}>
                      {item.autoCodeType}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />

            <Controller
              control={control}
              name="frozenIncentive"
              render={({ field, fieldState: { invalid, error } }) => (
                <div>
                  <TextField
                    label="Frozen Performance"
                    type="text"
                    id="ac-5"
                    placeholder="Enter"
                    onChange={field.onChange}
                    isDisabled={isFieldDisabled()}
                  />
                </div>
              )}
            />
            <Controller
              control={control}
              name="responsibility1"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={responsibility1Options}
                  label="Responsibility 1"
                  placeholder="Select"
                  selectedKey={data?.responsibility1}
                  isDisabled={isFieldDisabled()}
                  onSelectionChange={field.onChange}
                >
                  {(item) => (
                    <SelectOptions id={item.responsibility1}>
                      {item.responsibility1}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />

            <Controller
              control={control}
              name="responsibility2"
              render={({ field, fieldState: { invalid, error } }) => (
                <Select
                  items={responsibility2Options}
                  label="Responsibility 2 (Optional)"
                  placeholder="Select"
                  selectedKey={data?.responsibility2}
                  onSelectionChange={field.onChange}
                  isDisabled={isFieldDisabled()}
                >
                  {(item) => (
                    <SelectOptions id={item.responsibility2}>
                      {item.responsibility2}
                    </SelectOptions>
                  )}
                </Select>
              )}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-col lg:flex-row mt-6 gap-2">
            {!(
              roles.includes(ROLES.INCENTIVE_CLERK) ||
              roles.includes(ROLES.PI_ADMIN)
            ) && incentiveId ? (
              <>
                {data?.incentiveStatus === 'Active' && (
                  <>
                    <Button>Save</Button>
                    <Button
                      variant="outline"
                      onPress={() => setOpenInactivateModal(true)}
                    >
                      Inactivate
                    </Button>
                  </>
                )}
                {data?.incentiveStatus === 'Inactive' && (
                  <Button onPress={() => setOpenReactivateModal(true)}>
                    Reactivate
                  </Button>
                )}
              </>
            ) : (
              <Button
                type="button"
                onPress={() => setOpenAddModal(true)}
                isDisabled={isAddButtonDisabled}
              >
                Add
              </Button>
            )}
            <Button variant="outline" onPress={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </form>

      <Modal ariaLabel="add-modal" isOpen={openAddmodal}>
        <Modal.Header>
          <h3 className="flex item-center">Add Calculation Group</h3>
          <UnstyledButton>
            <BsX
              size="1.5rem"
              onClick={() => setOpenAddModal(false)}
              className="flex justify-end cursor-pointer "
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          <div className="text-sm w-full border-t border-gray-200">
            Are you sure you want to add the Calculation Group?
          </div>
          <div className="mt-2 ">
            <TextAreaField
              rows={5}
              placeholder="Describe reason"
              onChange={(event) => setAddIncentiveComment(event)}
            />
          </div>
        </Modal.Content>

        <Modal.Footer>
          <div className="flex flex-col lg:flex-row gap-2">
            <Button variant="outline" onPress={() => setOpenAddModal(false)}>
              Cancel
            </Button>
            <Button type="submit" onPress={handleAdd}>
              Add
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal ariaLabel="inactivate" isOpen={openInactivateModal}>
        <Modal.Header>
          <h3>Inactivate Calculation Group</h3>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setOpenInactivateModal(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          <p className="text-sm text-gray">
            Are you sure you want to inactivate the Calculation Group?
          </p>
          <p className="text-sm text-gray">
            Any associated position codes will get inactivated.
          </p>

          <div className="mt-4">
            <TextAreaField
              maxLength={500}
              rows={4}
              placeholder="Describe reason"
              onChange={(event) => setInactivateComment(event)}
            />
          </div>
        </Modal.Content>

        <div className="flex flex-col lg:justify-end lg:flex-row gap-2 mb-6 mt-2">
          <Button
            variant="outline"
            onPress={() => setOpenInactivateModal(false)}
          >
            Cancel
          </Button>
          <Button
            isDisabled={
              roles.includes(ROLES.INCENTIVE_CLERK) ||
              roles.includes(ROLES.PI_ADMIN)
            }
            onPress={handleInactiveIncentive}
          >
            Inactivate
          </Button>
        </div>
      </Modal>

      <Modal
        ariaLabel="reactivate"
        isOpen={openReactivateModal}
        className="w-full lg:w-2/5 h-3/4"
      >
        <Modal.Header>
          <h3>Reactivate Calculation Group</h3>
          <UnstyledButton>
            <BsX
              size="2rem"
              onClick={() => setOpenReactivateModal(false)}
              className="cursor-pointer absolute sm:relative right-2 sm:right-0"
            />
          </UnstyledButton>
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to reactivate the Calculation Group?</p>
          <div className="mt-4">
            <TextAreaField
              maxLength={500}
              rows={5}
              placeholder="Describe reason"
              onChange={(event) => setReactivateComment(event)}
            />
          </div>
        </Modal.Content>

        <div className="flex flex-col lg:justify-end lg:flex-row gap-2 mb-6 mt-2">
          <Button
            variant="outline"
            onPress={() => setOpenReactivateModal(false)}
          >
            Cancel
          </Button>
          <Button
            isDisabled={
              roles.includes(ROLES.INCENTIVE_CLERK) ||
              roles.includes(ROLES.PI_ADMIN)
            }
            onPress={handleReactiveIncentive}
          >
            Reactivate
          </Button>
        </div>
      </Modal>
    </>
  );
};
