import { Button, Checkbox, IconButton, Sidepane } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import Pagination from 'components/Pagination/Pagination';
import { useIncentiveList } from 'features/Incentive/api/queries';
import { useIncentiveListStore } from 'features/Incentive/state/incentive-list-store';
import { ContractRateCard } from 'features/Position/Components/Cards/ContractRateCard';
import { AddRateDrawer } from 'features/Position/Components/ContractRate/AddRateDrawer';
import { ContractRateFilter } from 'features/Position/Components/ContractRate/ContractRateFilter';
import useWindowSize from 'hooks/useWindowSize';
import { useState } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { BsPlus, BsX } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const ContractRate = () => {
  const [openAddRateDrawer, setOpenAddRateDrawer] = useState(false);
  const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);
  const store = useIncentiveListStore();
  const { width } = useWindowSize();

  const { data } = useIncentiveList({ ...store.params });

  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/position/contract-rate/detail">
          Positions
        </NavLink>,
      ]}
      heading={'Contract Rates'}
      headerAsideCont={
        <IconButton
          onPressChange={() => setOpenAddRateDrawer(true)}
          className="flex flex-row items-center w-full justify-end text-primary-400"
        >
          <BsPlus size="2rem" />
          <span>Add Rate</span>
        </IconButton>
      }
    >
      <Page.Section bg="gray">
        <div className=" flex flex-row pb-5 ">
          {width && width < 768 && (
            <>
              <IconButton
                onPressChange={() => setIsMobileFiltersOpen(true)}
                className="flex flex-row items-center w-full  text-primary-400"
              >
                <BiFilterAlt size={'1.5rem'} className="text-primary-400 " />
              </IconButton>
              <Sidepane
                ariaLabel="filter"
                isOpen={isMobileFiltersOpen}
                onOpenChange={() => setIsMobileFiltersOpen(false)}
                placement="bottom"
                className={' h-full'}
              >
                <div className="flex flex-row justify-between">
                  <h2>Filter By</h2>

                  <div className="flex flex-row mt-[-8px] justify-end  items-center text-primary-400">
                    <IconButton onPress={() => setIsMobileFiltersOpen(false)}>
                      <BsX />
                    </IconButton>
                  </div>
                </div>
                <ContractRateFilter />

                <div className="flex flex-col gap-2 w-full justify-end mt-4">
                  <Button variant="outline"> Cancel</Button>
                  <Button variant="filled"> Apply</Button>
                </div>
              </Sidepane>
            </>
          )}
        </div>

        {width && width > 768 && <ContractRateFilter />}

        <Checkbox>Select All </Checkbox>
        <div className=" grid grid-col-1 md:grid-cols-3 gap-4 my-4">
          <ContractRateCard />
          <ContractRateCard />
          <ContractRateCard />
          <ContractRateCard />
          <ContractRateCard />
        </div>
        {openAddRateDrawer && (
          <AddRateDrawer
            openAddRateDrawer={openAddRateDrawer}
            setOpenAddRateDrawer={setOpenAddRateDrawer}
          />
        )}

        <Pagination
          pageOptions={[9, 18, 27, 36, 45, 54]}
          totalCount={data?.totalCount}
          page={Number(store.params.page)}
          pageSize={Number(store.params.pageSize)}
          onPageChange={(s) => store.setPage(s.selected + 1)}
          onPageSizeChange={(ps) => store.setPageSize(ps)}
        />
      </Page.Section>
    </Page>
  );
};
