import { axios } from 'services/api/axios';
import {
  AddVariablePayload,
  AddVariableResponseResponse,
  CategoryCodeListParamsOptions,
  CategoryCodeListResponse,
  TableListParams,
  TableListResponse,
  UpdateVariablePayload,
} from '../types';

export const tableList = {
  get: (options?: TableListParams): Promise<TableListResponse> => {
    return axios.get(`/v1/variable-definitions/variables`, { params: options });
  },
};

export const variables = {
  get: (
    options?: CategoryCodeListParamsOptions
  ): Promise<CategoryCodeListResponse> => {
    return axios.get(`/v1/variables`, { params: options });
  },
  post: (body: AddVariablePayload): Promise<AddVariableResponseResponse> => {
    return axios.post(`/v1/variables`, body);
  },
  put: (
    varId: string,
    body: UpdateVariablePayload
  ): Promise<CategoryCodeListResponse> => {
    return axios.put(`/v1/variable-definitions/${varId}/variables`, body);
  },
};
