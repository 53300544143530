import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';

export const VicingHistoryCard = () => {
  return (
    <Card>
      {/* <UnstyledLink as={NavLink} to={'/'}> */}
      <Card.Body>
        <div>
          <LabeledText label="Effective Date">Sep 18, 2022</LabeledText>
        </div>
      </Card.Body>
      {/* </UnstyledLink> */}
    </Card>
  );
};
