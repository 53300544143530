import clsx from 'clsx';
import React, { ElementType, createContext, useContext } from 'react';
import { createStickyStore } from './sticky-store';
import { useStore } from 'zustand';

const StickyStoreContext = createContext<ReturnType<
  typeof createStickyStore
> | null>(null);

interface StickyWrapperProps {
  children: React.ReactNode;
  className?: string;
  wrapperElement?: ElementType;
}

export const StickyStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const store = React.useMemo(() => createStickyStore(), []);
  return (
    <StickyStoreContext.Provider value={store}>
      {children}
    </StickyStoreContext.Provider>
  );
};

export const useStickyStore = () => {
  const store = useContext(StickyStoreContext);
  if (!store) {
    throw new Error('useStickyStore must be used within StickyStoreProvider');
  }
  return useStore(store);
};

const StickyWrapper = ({
  children,
  className,
  wrapperElement: WrapperElement = 'div',
}: StickyWrapperProps): JSX.Element => {
  const store = useStickyStore();
  return (
    <WrapperElement
      className={clsx([
        store.isSticky && 'md:sticky md:bg-gray-100 z-30',
        className,
      ])}
    >
      {children}
    </WrapperElement>
  );
};

export default StickyWrapper;
