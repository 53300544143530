import { axios } from 'services/api/axios';
import { CommonPostResponse } from 'types/common';
import {
  IncentiveListParams,
  IncentiveListResponse,
  UpdateIncentiveReqBody,
  UpdateIncentiveResponse,
  IncentiveDetailParams,
  IncentiveList,
  ReactivateIncentiveReqBody,
  AddIncentiveReqBody,
} from '../types/types';

export const incentiveList = {
  get: (
    options?: IncentiveListParams
  ): Promise<IncentiveListResponse | undefined> => {
    return axios.get(`/v1/incentives`, {
      params: options,
    });
  },
};

export const inactiveIncentive = {
  put: (
    id: string,
    body?: UpdateIncentiveReqBody
  ): Promise<UpdateIncentiveResponse | undefined> => {
    return axios.put(`/v1/incentives/${id}/inactivate`, body);
  },
};
export const incentiveDetailsById = {
  get: (
    incentiveId: string,
    options?: IncentiveDetailParams
  ): Promise<IncentiveList> => {
    return axios.get(`/v1/incentives/${incentiveId}`);
  },
};
export const reactivateIncentive = {
  put: (
    body: ReactivateIncentiveReqBody,
    id: string
  ): Promise<IncentiveListResponse> => {
    return axios.put(`/v1/incentives/${id}/reactivate`, body);
  },
};

export const addIncentive = {
  post: (body: AddIncentiveReqBody): Promise<CommonPostResponse> => {
    return axios.post(`v1/incentives`, body);
  },
};
