import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

interface EditModelStore {
  isSticky: boolean;
  setSticky: (value: boolean) => void;
}

export const createStickyStore = () =>
  create<EditModelStore>()(
    devtools(
      immer((set) => ({
        isSticky: false,
        setSticky: (value) =>
          set(
            (state) => {
              state.isSticky = value;
            },
            false,
            'set-sticky'
          ),
      }))
    )
  );
