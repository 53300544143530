import { ObjectValues } from 'types/utils';

export const PTO_STATUS = {
  ADJUSTMENT: 'adjustment',
  APPROVED: 'approved',
  COMPANY_BLACKOUT: 'blackout',
  DENIED: 'denied',
  TAKEN: 'taken',
  REQUESTED: 'requested',
  REMOVED: 'removed',
} as const;

export const PTO_RECORD_SORT_OPTIONS = {
  SHIFT_DATE: 'shiftDate',
  CREW: 'crew',
  PAYCODE: 'paycode',
  STATUS: 'status',
} as const;

export const PTO_CLASS_NAME = {
  MINI_MILL: 'mini-mill',
  INTEGRATED_EXEMPT: 'integrated-exempt',
  INTEGRATED_NON_EXEMPT: 'integrated-non-exempt',
  PART_TIME_PAID_SICK: 'part-time-paid-sick',
} as const;

export const PTO_UNIT_OF_MEASURE = {
  DAYS: 'days',
  HOURS: 'hours',
} as const;

export const YES_NO = {
  YES: 'y',
  NO: 'n',
} as const;

export const PAYCODES = {
  INTEGRATED_NON_EXEMPT: [
    {
      paycode: 'PH',
      description: 'Personal Day - Half',
      duration: '.5',
      shiftAssignment: ['8-HR', '8-DAY HOUR', '12-HR AWS'],
    },
    {
      paycode: 'PD',
      description: 'Personal Day - Full',
      duration: '1',
      shiftAssignment: ['8-HR', '8-DAY HOUR', '12-HR AWS'],
    },
    {
      paycode: 'VH',
      description: 'Vacation Day - Half',
      duration: '.5',
      shiftAssignment: ['8-HR', '8-DAY HOUR', '12-HR AWS'],
    },
    {
      paycode: 'VD',
      description: 'Vacation Day - Full',
      duration: '1',
      shiftAssignment: ['8-HR', '8-DAY HOUR', '12-HR AWS'],
    },
  ],
  MINI_MILL_EIGHT_HOUR: [
    {
      paycode: 'P1',
      description: 'PTO - 1 Hour',
      duration: '1',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P2',
      description: 'PTO - 2 Hours',
      duration: '2',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P3',
      description: 'PTO - 3 Hours',
      duration: '3',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P4',
      description: 'PTO - 4 Hours',
      duration: '4',
      shiftAssignment: ['8-HR', '8-DAY HOUR'],
    },
    {
      paycode: 'P5',
      description: 'PTO - 5 Hours',
      duration: '5',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P6',
      description: 'PTO - 6 Hours',
      duration: '6',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P7',
      description: 'PTO - 7 Hours ',
      duration: '7',
      shiftAssignment: ['8-HR'],
    },
    {
      paycode: 'P8',
      description: 'PTO - 8 Hours ',
      duration: '8',
      shiftAssignment: ['8-HR', '8-DAY HOUR'],
    },
  ],
  MINI_MILL_EIGHT_DAY_HOUR: [
    {
      paycode: 'P4',
      description: 'PTO - 4 Hours',
      duration: '4',
      shiftAssignment: ['8-HR', '8-DAY HOUR'],
    },

    {
      paycode: 'P8',
      description: 'PTO - 8 Hours ',
      duration: '8',
      shiftAssignment: ['8-HR', '8-DAY HOUR'],
    },
  ],
  MINI_MILL_TWELVE_HOUR: [
    {
      paycode: 'P0',
      description: 'PTO - 10 Hours',
      duration: '10',
      shiftAssignment: ['12-HR AWS'],
    },
  ],
  INTEGRATED_EXEMPT_EIGHT_HOUR: ['PH', 'PD', 'VH', 'VD'],
  INTEGRATED_EXEMPT_TWELVE_HOUR: ['PD', 'VD'],
};

export const DATE_RANGE_PAYCODES = [
  'P1',
  'P2',
  'P3',
  'P4',
  'P5',
  'P6',
  'P7',
  'P8',
  'P0',
  'VH',
  'VD',
  'PH',
  'PD',
];

export const PTO_SORT = {
  NAME: 'name',
  PTO_DATE: 'ptodate',
  REQUESTED_ON: 'requestedOn',
};
export type PtoSort = ObjectValues<typeof PTO_SORT>;

export const PTO_UPDATE_CONTEXT = {
  EMP_PTO: 'emppto',
  CREW_PTO: 'crewpto',
  REQUESTS_PTO: 'request',
  MY_PTO: 'mypto',
  MY_SCHEDULE: 'myschedule',
  SCHEDULES: 'schedules',
  APPROVALS: 'approvals',
} as const;

export const PTO_ADD_CONTEXT = {
  MY_SCHEDULE: 'myschedule',
  MY_PTO: 'mypto',
  CREW_PTO: 'crewpto',
  EMP_PTO: 'emppto',
  SCHEDULES: 'schedules',
  APPROVALS: 'approvals',
} as const;

export type AddPTOContext = ObjectValues<typeof PTO_ADD_CONTEXT>;
