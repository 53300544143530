import { Button, MenuTrigger, Popover, UnstyledButton } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import Pagination from 'components/Pagination/Pagination';
import CardSkeleton from 'components/Skeleton/CardSkeleton';
import { useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { BsBagX, BsPencil, BsPlus } from 'react-icons/bs';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles';
import { useVariables } from '../../../../../features/payroll-table-maintenance/api/queries';
import { useVariableStore } from '../../../../../features/payroll-table-maintenance/store/variable-store';
import { AddCodeDrawer } from '../../../../../features/payroll-table-maintenance/components/AddCodeDrawer';

export const TableCategory = () => {
  const { id = '' } = useParams<'id'>();
  const { roles } = useUserRoles();
  const variableStore = useVariableStore();
  const { data, status } = useVariables({
    ...variableStore.params,
    varDefinitionId: id,
  });
  const [isActionButtonOpen, setIsActionButtonOpen] = useState<boolean>(false);
  const [isAddDrawerOpen, setIsAddDrawerOpen] = useState(false);
  const handleActionClose = () => {
    setIsActionButtonOpen(false);
    return true;
  };

  const toTitleCase = (str: string) => {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return titleCase;
  };

  const actionButtonPosition = () => {
    return (
      <div className="flex flex-row-reverse pb-3">
        {(roles.includes(ROLES.SUPER_ADMIN) ||
          roles.includes(ROLES.SUPER_PAYROLL_ADMIN) ||
          roles.includes(ROLES.PAYROLL_ADMIN)) && (
          <MenuTrigger isOpen={isActionButtonOpen}>
            <Button
              variant={'outline'}
              onPress={() => setIsActionButtonOpen(!isActionButtonOpen)}
            >
              Actions <BiChevronDown size="1.5rem" className="ml-2" />
            </Button>
            <Popover
              placement="bottom"
              isOpen={isActionButtonOpen}
              shouldCloseOnInteractOutside={handleActionClose}
              className={``}
            >
              <div className="flex-col">
                <UnstyledButton
                  className="flex gap-1 p-2 items-center"
                  onPress={() => {
                    setIsAddDrawerOpen(true);
                    setIsActionButtonOpen(false);
                  }}
                >
                  <span className="w-6 h-6 flex items-center">
                    <BsPlus size={'1.5rem'} className=" text-brand " />
                  </span>
                  Add
                </UnstyledButton>

                <NavLink
                  key="bc-1"
                  to="edit"
                  className="flex gap-1 p-2 items-center"
                >
                  <span className="w-6 h-6 flex items-center">
                    <BsPencil size="1rem" className=" text-brand" />
                  </span>
                  Edit
                </NavLink>
                <NavLink
                  key="bc-1"
                  to="inactivate"
                  className="flex gap-1 p-2 items-center text-danger"
                >
                  <span className="w-6 h-6 flex items-center">
                    <BsBagX size="1.25rem" />
                  </span>
                  Inactivate
                </NavLink>
              </div>
            </Popover>
          </MenuTrigger>
        )}
      </div>
    );
  };

  return (
    <>
      <Page
        heading={data?.varDefinitionName}
        breadcrumbs={[
          <NavLink key="bc-1" to="/admin">
            Admin
          </NavLink>,
          <NavLink key="bc-1" to="/admin/payroll-table-maintenance">
            Payroll Table Maintainece
          </NavLink>,
        ]}
        headerAsideCont={actionButtonPosition()}
      >
        <Page.Section>
          <AddCodeDrawer
            isOpenDrawer={isAddDrawerOpen}
            setIsAddDrawerOpen={setIsAddDrawerOpen}
            varDefinitionId={id}
            category={data?.varDefinitionName}
          />
          {status === 'pending' && (
            <div className=" grid grid-cols-3 gap-5  ">
              {Array.from(Array(9)).map((index: number) => (
                <CardSkeleton contentRows={1} variant="row-grid" key={index} />
              ))}
            </div>
          )}
          {status === 'success' && (
            <>
              <div className="grid md:grid-cols-3 gap-5 pb-10 pt-2 ">
                {data.items.map((e, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className=" rounded-lg bg-white py-3 px-4 text-lg font-semibold"
                      >
                        {e.code}
                        <span className="pl-3 text-base font-normal">
                          {toTitleCase(e.value)}
                        </span>
                      </div>
                    </>
                  );
                })}
              </div>
              <Pagination
                pageOptions={[9, 18, 27, 36, 45, 54]}
                totalCount={data.totalCount}
                page={Number(variableStore.params.page)}
                pageSize={Number(variableStore.params.pageSize)}
                onPageChange={(s) => variableStore.setPage(s.selected + 1)}
                onPageSizeChange={(ps) => variableStore.setPageSize(ps)}
              />
            </>
          )}
        </Page.Section>
      </Page>
    </>
  );
};
