import { Button } from '@uss/react-core';
import Page from 'components/Layouts/Page';
import Tabs from 'components/Tabs/Tabs';
import { ChangePercentageDrawer } from 'features/Position/Components/VicingRate/ChangePercentageDrawer';
import { SalaryGrade } from 'features/Position/Components/VicingRate/SalaryGrade';
import { VicingHistory } from 'features/Position/Components/VicingRate/VicingHistory';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export const VicingRate = () => {
  const [openPercentageDrawer, setOpenPercentageDrawer] = useState(false);
  const tabLinks = [
    {
      label: 'Salary Grades',
      element: <SalaryGrade />,
    },
    {
      label: 'History',
      element: <VicingHistory />,
    },
  ];
  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/">
          Positions
        </NavLink>,
      ]}
      heading={'Vicing Rates'}
      headerAsideCont={
        <div className="flex justify-end">
          <Button onPress={() => setOpenPercentageDrawer(true)}>
            {' '}
            Change Percentage
          </Button>
        </div>
      }
    >
      <Page.Section>
        <>
          <Tabs tabLinks={tabLinks} />
        </>
      </Page.Section>
      {openPercentageDrawer && (
        <ChangePercentageDrawer
          openPercentageDrawer={openPercentageDrawer}
          setOpenPercentageDrawer={setOpenPercentageDrawer}
        />
      )}
    </Page>
  );
};
