// import { UnstyledLink } from '@uss/react-core';
import Card from 'components/Card/Card';
// import { NavLink } from 'react-router-dom';

export const SalaryGradeCard = () => {
  return (
    <Card>
      {/* <UnstyledLink as={NavLink} to={'/'}> */}
      <Card.Body>
        <div className="flex flex-row justify-between">
          <span className="font-semibold">SG-08</span>
          <span>08G-08AK</span>
        </div>
      </Card.Body>
      {/* </UnstyledLink> */}
    </Card>
  );
};
