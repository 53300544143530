import Page from 'components/Layouts/Page';
import { useModelDetails } from 'features/model-listing/api/queries';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Detailscard } from 'features/model-listing/components/ModelDetails/ModelDetailsCard';
import { EditModelDetails } from 'features/model-listing/components/ModelEdit/EditModelDetails';
import { Alert } from '@uss/react-core';
import { Button } from '@uss/react-components';
import { BsPlus, BsTrash } from 'react-icons/bs';
import { WeekCardLoader } from 'features/model-listing/components/ModelDetails/WeekCardLoader';
import CardsLoader from 'components/Loaders/CardsLoader';
import { useEditModelStore } from 'features/model-listing/state/edit-model-store';
import { MODEL_STATUS } from 'features/model-listing/constants';
import { useState } from 'react';
import DeleteWeeksCrewsRotationsDrawer from '../../../features/model-listing/components/ModelEdit/DeleteWeeksCrewRotations/DeleteWeeksCrewRotationsDrawer';
import { useEffect } from 'react';
import { useModelDetailStore } from 'features/model-listing/state/model-detail-store';
import { AddWeeksCrewsRotationsDrawer } from 'features/model-listing/components/ModelEdit/AddWeeksCrewDrawer/AddWeeksCrewRotationsDrawer';
import StickyWrapper, {
  StickyStoreProvider,
} from 'components/StickyWrapper/StickyWrapper';
import StickyButton from 'components/StickyWrapper/StickyButton';

export const EditModel = () => {
  const { id = '' } = useParams<'id'>();
  const store = useEditModelStore();
  const modelDetailStore = useModelDetailStore();
  const { data, status } = useModelDetails(id, store.params);

  //Delete Weeks/Crew Rotations Drawer
  const [openDeleteWeeksCrewDrawer, setOpenDeleteWeeksCrewDrawer] =
    useState(false);
  const { data: modelDetail } = useModelDetails(id, {
    page: 1,
    pageSize: 52,
  });
  useEffect(() => {
    modelDetail && store.updateWeeks(modelDetail.weeks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelDetail]);
  useEffect(() => {
    modelDetail && modelDetailStore.updateModelDetail(modelDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelDetail]);
  const [openAddWeeksCrewDrawer, setOpenAddWeeksCrewDrawer] =
    useState<boolean>(false);
  return (
    <>
      <Page
        breadcrumbs={[
          <NavLink key="bc-1" to="/scheduling">
            Scheduling
          </NavLink>,
          <NavLink key="bc-2" to="/non-union-scheduling/modeling">
            Modeling
          </NavLink>,
        ]}
        heading={'Edit Model Details'}
      >
        <StickyStoreProvider>
          <StickyWrapper
            wrapperElement="section"
            className="px-5 pb-4 last-of-type:pb-0 top-[71px]"
          >
            <div className="flex gap-5 pb-2 items-center">
              <div className="flex gap-2 flex-grow items-center">
                <StickyButton
                  enabledText="Unpin Header"
                  disabledText="Pin Header"
                />

                {data?.modelStatus === MODEL_STATUS.CHECKED_OUT && (
                  <div className="w-3/4">
                    <Alert
                      variant="info"
                      message="You have checked out this model. Click on Save & Publish to check the model back in to Active status."
                    ></Alert>
                  </div>
                )}
              </div>
              <div className="flex flex-nowrap gap-4">
                <Button
                  variant="text"
                  className="text-sm  text-red-600 cursor-pointer"
                  onClick={() => setOpenDeleteWeeksCrewDrawer(true)}
                  disabled={modelDetail?.weeks.length === 0}
                >
                  <BsTrash id="delete" className="text-sm" />
                  <span className=" ml-2">Week(s)/Crew(s)</span>
                </Button>
                <Button
                  variant="text"
                  className="text-sm text-primary-400 cursor-pointer"
                  onClick={() => setOpenAddWeeksCrewDrawer(true)}
                  disabled={data?.totalWeeks === 52}
                >
                  <BsPlus size={'1.5rem'} className="text-sm" />
                  <span>Week(s)/Crew(s)</span>
                </Button>
              </div>
              <AddWeeksCrewsRotationsDrawer
                openDrawer={openAddWeeksCrewDrawer}
                setOpenDrawer={setOpenAddWeeksCrewDrawer}
                data={data}
              />
            </div>

            {status === 'success' ? (
              <Detailscard data={data} />
            ) : (
              <CardsLoader variant="row-grid" count={1} hasAvatar />
            )}
          </StickyWrapper>
        </StickyStoreProvider>
        <Page.Section>
          {status === 'success' ? (
            <EditModelDetails data={data} />
          ) : (
            <WeekCardLoader
              numberOfWeeks={6}
              numberOfCrewRotations={3}
              numberOfGridCols={2}
            />
          )}
          <DeleteWeeksCrewsRotationsDrawer
            openDrawer={openDeleteWeeksCrewDrawer}
            setOpenDrawer={setOpenDeleteWeeksCrewDrawer}
          />
        </Page.Section>
      </Page>
    </>
  );
};
