import { UnstyledLink } from '@uss/react-core';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { NavLink } from 'react-router-dom';

export const HistoryCard = () => {
  return (
    <Card>
      <UnstyledLink
        as={NavLink}
        to={'/position/contract-rate/history/history-detail'}
      >
        <Card.Body>
          <div className="flex flex-row justify-between items-start">
            <div className="w-1/2">
              <LabeledText label="Effective Date">Aug 05, 2025</LabeledText>
            </div>
            <div className="w-1/2">
              <LabeledText label="End Date">-</LabeledText>
            </div>
          </div>
        </Card.Body>
      </UnstyledLink>
    </Card>
  );
};
