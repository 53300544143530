import {
  Button,
  DatePicker,
  IconButton,
  Sidepane,
  TextField,
} from '@uss/react-core';
import { BsInfoCircle, BsX } from 'react-icons/bs';

interface PercentageDrawerProps {
  openPercentageDrawer: boolean;
  setOpenPercentageDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ChangePercentageDrawer = ({
  openPercentageDrawer,
  setOpenPercentageDrawer,
}: PercentageDrawerProps) => {
  const handleClose = () => {
    setOpenPercentageDrawer(false);
  };

  return (
    <Sidepane
      ariaLabel="filter"
      isOpen={openPercentageDrawer}
      onOpenChange={handleClose}
    >
      <div className="flex flex-row justify-between mb-4">
        <h2>Change Percentage</h2>
        <div className="flex flex-row mt-[-8px] items-center text-primary-400">
          <IconButton onPress={handleClose}>
            <BsX />
          </IconButton>
        </div>
      </div>
      <div className="md:w-96 w-60 mt-4">
        <TextField label="Percentage" type="text" placeholder="Enter Text" />
        <div className="flex flex-row items-center text-sm gap-2 mt-2">
          <BsInfoCircle />
          <span>For a 3.5% increase, enter 3.5</span>
        </div>
        <div className="mt-4">
          <DatePicker label="Effective Date"></DatePicker>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-end mt-4 fixed bottom-4 right-4">
        <Button variant="outline"> Cancel</Button>
        <Button variant="filled"> Apply Changes</Button>
      </div>
    </Sidepane>
  );
};
