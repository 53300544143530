import React from 'react';
import { HolidayType, useHolidays } from 'features/holidays';
import { Timecard } from 'features/timecards/components/TimecardDetails/Timecard';
import { EmployeeTimecardList } from 'features/timecards/types';
import { SHIFT_ASSIGNMENT, ShiftAssignment } from 'features/people/types';
import { useUpdateEmployeeTimecard } from 'features/timecards/api/updateTimecard';
import { useCreateAdjustment } from 'features/timecards/api/createAdjustment';
import { useCreateTimecard } from 'features/timecards/api/createTimecard';
import { format, parse } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import TimecardDetailsLoader from 'features/timecards/components/TimecardDetails/Loaders/TimecardDetailsLoader';
import { usePersonDetails } from 'features/people/api';
import NoResults from 'components/NoResults/NoResults';

interface TimecardApprovalsProps {
  isFetchingTimecards: boolean;
  employeeTimecardObj: EmployeeTimecardList;
  isTimecardDisabled: boolean;
  shiftAssignment: ShiftAssignment | null;
  holidayType: HolidayType;
  setAdjustment?: (value: number | null) => void;
  width: number | undefined;
  isCrewNotAssigned?: boolean;
}
const TimecardApprovals = ({
  isFetchingTimecards,
  employeeTimecardObj,
  isTimecardDisabled,
  shiftAssignment,
  holidayType,
  setAdjustment,
  width,
  isCrewNotAssigned,
}: TimecardApprovalsProps) => {
  const state = useNonUnionStore();
  const { ussId: id = '' } = useParams<'ussId'>();
  const week = state.params.week;
  const crew = state.crew?.id;

  const { data: employee, isFetching: isEmployeeFetching } =
    usePersonDetails(id);

  const { data: holidayList } = useHolidays(holidayType);
  const formattedWeek = week
    ? format(parse(week, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd').toString()
    : '';
  const createTimecard = useCreateTimecard(id, crew ? crew : '', formattedWeek);
  const updateEmployeeTimecard = useUpdateEmployeeTimecard(
    id,
    employeeTimecardObj.items[0].id
  );
  const createAdjustment = useCreateAdjustment(
    id,
    employeeTimecardObj.items[0].adjustment,
    setAdjustment
  );

  const isUpdatingTimecards =
    createTimecard.isPending ||
    updateEmployeeTimecard.isPending ||
    createAdjustment.isPending;

  const shouldDisplayLoader =
    isEmployeeFetching ||
    isFetchingTimecards ||
    isUpdatingTimecards ||
    !employee ||
    !width;

  return shouldDisplayLoader ? (
    <TimecardDetailsLoader width={width} />
  ) : (
    <section>
      <h3 className="sr-only">Timecard Scheduling</h3>
      {!isCrewNotAssigned ? (
        <Timecard
          employeeTimecardObj={employeeTimecardObj}
          isTimecardDisabled={isTimecardDisabled}
          holidayList={holidayList}
          shiftAssignment={shiftAssignment ?? SHIFT_ASSIGNMENT.EIGHT_HOUR}
          createTimecard={createTimecard}
          updateEmployeeTimecard={updateEmployeeTimecard}
          createAdjustment={createAdjustment}
          width={width}
          employee={employee}
        />
      ) : (
        <NoResults
          title="Not assigned to a crew"
          description="Your Supervisor needs to assign you to a crew before you can enter your timecards."
          width={'300'}
          height={'300'}
        />
      )}
    </section>
  );
};

export default TimecardApprovals;
