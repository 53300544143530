import Page from 'components/Layouts/Page';
import LabeledText from 'components/Typography/LabeledText';
import { NavLink } from 'react-router-dom';

export const HistoryDetail = () => {
  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/">
          Position
        </NavLink>,
        <NavLink key="pc-1" to="/position/contract-rate">
          Contract Rates
        </NavLink>,
        <NavLink key="pc-1" to="/position/contract-rate/history">
          History
        </NavLink>,
      ]}
      heading={'Details'}
    >
      <Page.Section>
        <div className="bg-white pt-4 sm:bg-white rounded-md border border-blue-600 mb-2 pb-4 px-3">
          <div className="flex flex-row justify-start">
            <LabeledText label="Payroll Loc">A34</LabeledText>
            <span className="md:border-r border-gray-200 md:ml-18 md:mr-4" />
            <LabeledText label="Bargaining Unit">01</LabeledText>
            <span className="md:border-r  border-gray-200 md:ml-18 md:mr-2" />
            <LabeledText label="Updated By">John Doe</LabeledText>
            <span className="md:border-r  border-gray-200 md:ml-18 md:mr-2" />
            <LabeledText label="Updated Date">July 19,2022</LabeledText>
          </div>
        </div>

        <div className="bg-white pt-4 sm:bg-white rounded-md mb-2 pb-4 px-3">
          <div className=" mt-2 md:w-3/4 mx-auto justify-center">
            <span className="font-semibold">Labor Grade: 01</span>
            <div className="flex flex-col md:flex-row text-sm justify-between w-full mt-4">
              <LabeledText label="Standard Rate">25.470</LabeledText>
              <LabeledText label="Converted Base Rate">9.780</LabeledText>
              <LabeledText label="Starter Standard Rate">24.780</LabeledText>
              <LabeledText label="Starter Converted Base Rate">
                9.780
              </LabeledText>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 02</span>
            <div className="flex flex-col md:flex-row text-sm justify-between w-full mt-4">
              <LabeledText label="Standard Rate">25.470</LabeledText>
              <LabeledText label="Converted Base Rate">9.780</LabeledText>
              <LabeledText label="Starter Standard Rate">24.780</LabeledText>
              <LabeledText label="Starter Converted Base Rate">
                9.780
              </LabeledText>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 03</span>
            <div className="flex flex-col md:flex-row text-sm justify-between w-full mt-4">
              <LabeledText label="Standard Rate">25.470</LabeledText>
              <LabeledText label="Converted Base Rate">9.780</LabeledText>
              <LabeledText label="Starter Standard Rate">24.780</LabeledText>
              <LabeledText label="Starter Converted Base Rate">
                9.780
              </LabeledText>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 04</span>
            <div className="flex flex-col md:flex-row text-sm justify-between w-full mt-4">
              <LabeledText label="Standard Rate">25.470</LabeledText>
              <LabeledText label="Converted Base Rate">9.780</LabeledText>
              <LabeledText label="Starter Standard Rate">24.780</LabeledText>
              <LabeledText label="Starter Converted Base Rate">
                9.780
              </LabeledText>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 05</span>
            <div className="flex flex-col md:flex-row text-sm justify-between w-full mt-4">
              <LabeledText label="Standard Rate">25.470</LabeledText>
              <LabeledText label="Converted Base Rate">9.780</LabeledText>
              <LabeledText label="Starter Standard Rate">24.780</LabeledText>
              <LabeledText label="Starter Converted Base Rate">
                9.780
              </LabeledText>
            </div>
            <div className="border-t-2 my-4" />
          </div>
        </div>
      </Page.Section>
    </Page>
  );
};
