import { z } from 'zod';

export const AddIncentiveReqBodySchema = z.object({
  payrollLocationOrgId: z
    .string({ required_error: 'Payroll location is requried' })
    .nonempty({ message: 'Payroll location is required' })
    .min(1, { message: 'Payroll location is required' }),
  calcGroup: z
    .string({ required_error: 'Calc group  is requried' })
    .nonempty({ message: 'Calc group is requried' })
    .min(1, { message: 'Payroll location is required' }),

  applicationCode: z.string().nonempty(),
  calcGroupDescription: z.string().nonempty(),
  minAuditIncentive: z.number(),
  maxAuditIncentive: z.number(),
  minPayoutIncentive: z.number(),
  maxPayoutIncentive: z.number(),
  frozenIncentive: z.number(),
  dollarCode: z.string(),
  incentiveType: z.string(),
  autoCode6Type: z.string(),
  responsibility1: z.string(),
  responsibility2: z.string(),
  comments: z.string(),
});
