import { Checkbox } from '@uss/react-core';
import Card from 'components/Card/Card';
import LabeledText from 'components/Typography/LabeledText';
import { BsClockHistory, BsPencil } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const ContractRateCard = () => {
  return (
    <Card>
      <Card.Body>
        <div className="flex flex-row gap-4">
          <Checkbox />
          <span className="font-semibold text-lg">Payroll Location : A34</span>
        </div>
        <div className="border-t-2 my-4 border-gray-300" />
        <div className="flex flex-wrap justify-between">
          <div className="w-1/2">
            <LabeledText label="Bargaining Unit">01</LabeledText>
          </div>

          <div className="w-1/2">
            <LabeledText label="Status">Pending</LabeledText>
          </div>
        </div>
        <div className="border-t-2 my-4 border-gray-300" />
        <div className="flex flex-row justify-between">
          <NavLink
            to={'/position/contract-rate/edit'}
            className="flex flex-row w-1/2 gap-2 text-primary-400 justify-end"
          >
            <span className="flex items-center">
              <BsPencil />
            </span>
            <span>Edit</span>
          </NavLink>
          <div className="border-r-2 mx-6 border-gray-300" />
          <NavLink
            to={'/position/contract-rate/history'}
            className="flex flex-row w-1/2 gap-2 text-primary-400"
          >
            <span className="flex items-center">
              <BsClockHistory />
            </span>
            History
          </NavLink>
        </div>
      </Card.Body>
    </Card>
  );
};
