import { ROLES, RolesType } from 'features/roles';

/**
 * These roles are used to control access to pages within the application.  They referenced in routes.tsx and the <SideNav/> component.
 */

export const PAGE_ROLES: Record<string, RolesType[]> = {
  NON_UNION_PAY: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  NON_UNION_PAY_MASS_EDIT: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  NON_UNION_SCHED: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAY_APPROVER,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.NONUNION_EMPLOYEE,
  ],
  NON_UNION_SCHED_CREW_LISTING: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  NON_UNION_SCHED_EMP_DETAILS: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  NON_UNION_SCHED_MY_SCHEDULE: [
    ROLES.NONUNION_EMPLOYEE,
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
  ],
  NON_UNION_SCHED_MASS_EDIT: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  PAY_PULLOFF: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
  ],
  MILEAGE_APPROVAL: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAY_APPROVER,
    ROLES.SCHEDULE_PAY_VIEWER,
  ],
  HOLIDAY_LIST: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.NONUNION_EMPLOYEE,
    ROLES.SCHEDULING_ADMIN,
    ROLES.UNION_EMPLOYEE,
    ROLES.HR_ROLE,
    ROLES.HR_LR_ROLE,
    ROLES.PROB_EMP_REVIEWER,
    ROLES.AREA_MANAGER,
  ],
  PAY_CYCLES: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAY_APPROVER,
    ROLES.PAYROLL_ADMIN,
    ROLES.SCHEDULE_PAY_VIEWER,
    ROLES.NONUNION_EMPLOYEE,
    ROLES.SCHEDULING_ADMIN,
    ROLES.UNION_EMPLOYEE,
  ],
  ADMIN: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.SCHEDULING_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  SECURITY: [
    ROLES.SUPER_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
  ],
  CREW_MAINTENANCE: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.SCHEDULING_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  PAY_CODE_MAINTENANCE: [
    ROLES.SUPER_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
  ],
  MY_PTO: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.NONUNION_EMPLOYEE,
  ],
  PTO_VACATION: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  SEARCH_EMP_PTO: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  PTO_REQUESTS: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  CREW_PTO: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  ORG_STRUCTURE_DIVISIONS: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
  ],
  PROB_EMP_ADMIN: [ROLES.SUPER_ADMIN],
  PROB_EMP_EMPLOYEE_ACKNOWLEDGEMENT: [ROLES.UNION_EMPLOYEE],
  PROB_EMP_AREA_MANAGER_VIEW: [ROLES.AREA_MANAGER],
  PROB_EMP_REVIEWER_VIEW: [ROLES.PROB_EMP_REVIEWER, ROLES.AREA_MANAGER],
  PROB_EMP_HR_LR: [
    ROLES.HR_LR_ROLE,
    //TODO: uncomment the below line once api is configured to work for Super Admins
    // ROLES.SUPER_ADMIN
  ],
  PROB_EMP_BARGAINING_UNIT_TOTAL: [ROLES.HR_ROLE, ROLES.SUPER_ADMIN],
  MY_MILEAGE: [
    ROLES.NONUNION_EMPLOYEE,
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],

  ADD_CREW: [
    ROLES.SUPER_ADMIN,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
  ],
  MODELING: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.NONUNION_EMPLOYEE,
  ],
  PAYROLL_TABLE_MAINTENANCE: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.PI_ADMIN,
  ],
  POSITION: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAY_APPROVER,
    ROLES.T_A_PLANT_COORDINATOR,
    ROLES.PAYROLL_ADMIN,
    ROLES.NONUNION_EMPLOYEE,
  ],
  INCENTIVE_TABLE: [
    ROLES.SUPER_ADMIN,
    ROLES.SUPER_PAYROLL_ADMIN,
    ROLES.PAYROLL_ADMIN,
    ROLES.PI_ADMIN,
    ROLES.INCENTIVE_CLERK,
    ROLES.INCENTIVE_ADMIN,
  ],
};
