import { VicingHistoryCard } from '../Cards/VicingHistoryCard';

export const VicingHistory = () => {
  return (
    <div className="grid grid-cols-3 gap-4 my-2">
      <VicingHistoryCard />
      <VicingHistoryCard />
      <VicingHistoryCard />
      <VicingHistoryCard />
      <VicingHistoryCard />
      <VicingHistoryCard />
    </div>
  );
};
