import { addWeeks, format, parse, subWeeks } from 'date-fns';
import { findIndex } from 'lodash';
import {
  SelectedEmployee,
  SortOptions,
  CrewTimecardListOptions,
  TimecardFeature,
  TimecardStatusCode,
  EmpClasses,
  PrimaryCrewPtoType,
} from 'features/timecards/types';
import { SortDirection } from 'types/common';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { Org } from 'features/org/types';
import useDefaultWeek from './useDefaultWeek';
interface TimecardParams
  extends Omit<CrewTimecardListOptions, 'crewId' | 'week'> {
  week: string | null;
}
interface NonUnionState {
  feature: TimecardFeature | null;
  params: TimecardParams;
  selectedPlant: Org | null;
  selectedLocation: Org | 'all' | null;
  crew: Org | null;
  adjustment: number | null;
  formattedDate?: Date;
  selectedEmployees: SelectedEmployee[];
  isGateSystemAvailable: 'y' | 'n' | null;
  primaryCrewPtos?: PrimaryCrewPtoType[];
  processId?: string;
  processStatus?:
    | 'in_progress'
    | 'completed'
    | 'aborted'
    | 'error'
    | 'terminated';
  setFeature: (value: TimecardFeature) => void;
  setSearch: (value: string) => void;
  toggleEmployee: (value: SelectedEmployee) => void;
  toggleAllEmployees: (value: SelectedEmployee[]) => void;
  clearEmployees: () => void;
  clearSearch: () => void;
  setSort: (val: { type: SortOptions; direction: SortDirection }) => void;
  setPlant: (value: Org) => void;
  setPayrollLocation: (value: Org | 'all') => void;
  setCrew: (value: Org) => void;
  setAdjustment: (value: number | null) => void;
  setStatus: (value: TimecardStatusCode | 'all') => void;
  setEmpClass: (value: EmpClasses | 'all') => void;
  setPage: (value: string) => void;
  setPageSize: (value: string) => void;
  setWeek: (value: [Date | null, Date | null]) => void;
  incrementWeek: (by?: number) => void;
  decrementWeek: () => void;
  setGateSystem: (value: 'y' | 'n' | null) => void;
  setPrimaryCrewPtos: (value: PrimaryCrewPtoType[]) => void;
  setProcessId: (value: string) => void;
  setProcessStatus: (
    value:
      | 'in_progress'
      | 'completed'
      | 'aborted'
      | 'error'
      | 'terminated'
      | undefined
  ) => void;
}

export const useNonUnionStore = create<NonUnionState>()(
  devtools(
    immer(
      persist(
        (set) => ({
          params: {
            week: null,
            sortBy: 'name',
            sortType: SortDirection.ASC,
            page: '1',
            pageSize: '10',
          },
          feature: null,
          selectedEmployees: [],
          adjustment: null,
          crew: null,
          selectedLocation: null,
          selectedPlant: null,
          isGateSystemAvailable: null,
          primaryCrewPtos: [],
          processId: '',
          processStatus: undefined,
          setFeature: (value) =>
            set((state) => {
              if (state.feature !== value) {
                state.feature = value;
                if (
                  state.selectedPlant &&
                  typeof state.selectedPlant.weekBeginDayIndex === 'number'
                ) {
                  state.params.week = useDefaultWeek({
                    plantType: state.selectedPlant.plantType,
                    feature: value,
                    weekStartDate: state.selectedPlant.weekBeginDayIndex,
                  });
                }
              }
            }),
          setPlant: (value) =>
            set(
              (state) => {
                state.selectedPlant = value;
                state.params.page = '1';
                state.selectedLocation = null;
                state.crew = null;
                if (
                  typeof value.weekBeginDayIndex === 'number' &&
                  state.feature
                ) {
                  state.params.week = useDefaultWeek({
                    plantType: value.plantType,
                    feature: state.feature,
                    weekStartDate: value.weekBeginDayIndex,
                  });
                }
              },
              false,
              'set-plant'
            ),
          setPayrollLocation: (value) =>
            set(
              (state) => {
                state.selectedLocation = value;
                state.crew = null;
                state.params.page = '1';
                if (value !== 'all' && state.feature !== null) {
                  state.params.week = useDefaultWeek({
                    plantType: value.plantType,
                    feature: state.feature,
                    weekStartDate: value.weekBeginDayIndex,
                    cutOffDate: value.cutOffDate,
                  });
                }
              },
              false,
              'set-payroll-location'
            ),
          toggleEmployee: (value) =>
            set((state) => {
              const i = findIndex(
                state.selectedEmployees,
                (employee) => employee.timecardId === value.timecardId
              );
              if (i >= 0) {
                state.selectedEmployees.splice(i, 1);
              } else {
                state.selectedEmployees.push(value);
              }
            }),
          toggleAllEmployees: (value) => {
            set((state) => {
              state.selectedEmployees = value;
            });
          },
          clearEmployees: () => {
            set({ selectedEmployees: [] });
          },
          setSearch: (value) =>
            set(
              (state) => {
                state.params.search = value;
                state.params.page = '1';
              },
              false,
              'set-search'
            ),
          clearSearch: () =>
            set((state) => {
              delete state.params.search;
            }),
          setSort: (value) =>
            set(
              (state) => {
                state.params.sortBy = value.type;
                state.params.sortType = value.direction;
                state.params.page = '1';
              },
              false,
              'set-sort'
            ),
          setCrew: (value) => {
            set((state) => {
              state.crew = value;
              state.selectedEmployees = [];
              state.params.page = '1';
              state.adjustment = null;
            });
          },
          setStatus: (value) =>
            set((state) => {
              if (value === 'all') {
                delete state.params.status;
              } else {
                state.params.status = value;
              }

              state.params.page = '1';
            }),
          setEmpClass: (value) =>
            set((state) => {
              if (value === 'all') {
                delete state.params.empClassFilter;
              } else {
                state.params.empClassFilter = value;
              }
              state.params.page = '1';
            }),
          setPage: (value) =>
            set((state) => {
              state.params.page = value;
            }),
          setPageSize: (value) =>
            set((state) => {
              state.params.pageSize = value;
            }),
          setWeek: (value) =>
            set((state) => {
              if (value[0]) {
                state.params.week = '';
                const week = format(value[0], 'yyyy-MM-dd');
                const formatWeek = parse(week, 'yyyy-MM-dd', new Date());

                state.params.week = format(formatWeek, 'yyyy-MM-dd');
                state.adjustment = null;
              }
            }),
          incrementWeek: (by = 1) =>
            set((state) => {
              if (!!state.params.week) {
                const formatWeek = parse(
                  state.params.week,
                  'yyyy-MM-dd',
                  new Date()
                );

                state.params.week = format(
                  addWeeks(formatWeek, by),
                  'yyyy-MM-dd'
                );

                state.selectedEmployees = [];
                state.adjustment = null;
              }
            }),
          decrementWeek: () =>
            set((state) => {
              if (!!state.params.week) {
                const formatWeek = parse(
                  state.params.week,
                  'yyyy-MM-dd',
                  new Date()
                );

                state.params.week = format(
                  subWeeks(formatWeek, 1),
                  'yyyy-MM-dd'
                );
                state.selectedEmployees = [];
                state.adjustment = null;
              }
            }),
          setAdjustment: (value) => {
            set((state) => {
              state.adjustment = value;
            });
          },
          setGateSystem: (value) => {
            set((state) => {
              state.isGateSystemAvailable = value;
            });
          },
          setPrimaryCrewPtos: (value) => {
            set((state) => {
              state.primaryCrewPtos = value;
            });
          },
          setProcessId: (value) => {
            set((state) => {
              state.processId = value;
            });
          },
          setProcessStatus: (value) => {
            set((state) => {
              state.processStatus = value;
            });
          },
        }),
        {
          name: 'non-union-state',
          storage: createJSONStorage(() => sessionStorage),
        }
      )
    )
  )
);
