import Page from 'components/Layouts/Page';
import LabeledText from 'components/Typography/LabeledText';
import { NavLink } from 'react-router-dom';
import { HistoryCard } from '../Cards/HistoryCard';

export const ContractRateHistory = () => {
  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/position/contract-rate">
          Contract Rates
        </NavLink>,
      ]}
      heading={'History'}
    >
      <Page.Section>
        <div className="bg-white pt-4 sm:bg-white rounded-md border border-blue-600 mb-2 pb-4 px-3">
          <div className="flex flex-row justify-start mb-2">
            <LabeledText label="Payroll Loc">A34</LabeledText>
            <span className=" md:border-r border-gray-200 md:ml-18 md:mr-4" />
            <LabeledText label="Bargaining Unit">01</LabeledText>
          </div>
        </div>

        <div className=" grid grid-col-1 md:grid-cols-3 gap-4 my-4">
          <HistoryCard />
          <HistoryCard />
          <HistoryCard />
          <HistoryCard />
          <HistoryCard />
          <HistoryCard />
        </div>
      </Page.Section>
    </Page>
  );
};
