import Page from 'components/Layouts/Page';
import { IncentiveForm } from '../Forms/IncentiveForm';
import { useParams } from 'react-router-dom';

export const IncentiveDetailTab = () => {
  const { id = '' } = useParams<'id'>();

  return (
    <Page>
      <IncentiveForm incentiveId={id} />
    </Page>
  );
};
