import { Button, TextField } from '@uss/react-core';
import { notify } from 'components/Notification/useNotification';
import { useUpdateVariable } from 'features/payroll-table-maintenance/api/mutations';
import {
  CategoryCodeListResponse,
  UpdateVariablePayload,
} from 'features/payroll-table-maintenance/types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { queryClient } from 'services/api/react-query';

interface EditCodeFormProps {
  variableList: CategoryCodeListResponse;
}

export const EditCodeForm = ({ variableList }: EditCodeFormProps) => {
  const navigate = useNavigate();
  const { id = '' } = useParams<'id'>();

  const navigateToPrevPage = () => {
    navigate(`/admin/payroll-table-maintenance/category/${id}`);
  };
  const { control, handleSubmit } = useForm<UpdateVariablePayload>({
    defaultValues: {
      items: variableList.items,
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'items', // Name of the array field
  });

  const { mutateAsync: updateVariableMutate } = useUpdateVariable(id);

  const onSubmit = async (data: UpdateVariablePayload) => {
    const changedCodes = {
      items: data.items.filter((item, index) => {
        return item.value !== variableList.items[index]?.value;
      }),
    };

    await updateVariableMutate(changedCodes, {
      onSuccess: async () => {
        navigateToPrevPage();
        notify.success({
          message: `You have successfully updated ${
            variableList.varDefinitionName ? variableList.varDefinitionName : ''
          } record.`,
        });
        await queryClient.resetQueries({
          queryKey: ['variables'],
          type: 'active',
        });
      },
    });
  };
  const toTitleCase = (str: string) => {
    const titleCase = str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');

    return titleCase;
  };
  return (
    <form className="flex flex-col flex-grow" onSubmit={handleSubmit(onSubmit)}>
      <div className=" grid md:grid-cols-3 gap-5 pb-10 pt-2 ">
        {fields.map((field, index) => {
          return (
            <>
              <div
                key={field.id}
                className="rounded-lg bg-white py-3 px-4 flex gap-1 flex-nowrap items-center "
              >
                <span className="text-lg font-semibold">{field.code}</span>

                <Controller
                  name={`items.${index}.value`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      aria-label={field.value}
                      placeholder={toTitleCase(field.value)}
                    />
                  )}
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="flex gap-4 md:flex-row flex-col flex-wrap ">
        <Button type="submit" variant="filled" color="primary">
          Update
        </Button>
        <Button variant="outline" color="primary" onPress={navigateToPrevPage}>
          Cancel
        </Button>
      </div>
      {/* <Pagination
                  pageOptions={[9, 18, 27, 36, 45, 54]}
                  totalCount={data.totalCount}
                  page={Number(variableStore.params.page)}
                  pageSize={Number(variableStore.params.pageSize)}
                  onPageChange={(s) => variableStore.setPage(s.selected + 1)}
                  onPageSizeChange={(ps) => variableStore.setPageSize(ps)}
                /> */}
    </form>
  );
};
