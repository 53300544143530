import { useQuery } from '@tanstack/react-query';
import { IncentiveDetailParams, IncentiveListParams } from '../types/types';
import { incentiveDetailsById, incentiveList } from './api';

export const useIncentiveList = (options?: IncentiveListParams) => {
  return useQuery({
    queryFn: () => incentiveList.get(options),
    queryKey: ['incentive-list', { ...options }],
  });
};

export const useIncentiveDetails = (
  incentiveId: string,
  options?: IncentiveDetailParams
) => {
  return useQuery({
    queryFn: () => incentiveDetailsById.get(incentiveId, options),
    queryKey: ['incentive-details', { incentiveId, options }],
  });
};
