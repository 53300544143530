import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { IncentiveListParams, IncentiveStatus } from '../types/types';
import { INCENTIVE_STATUS } from '../constants';

interface IncentiveList {
  params: IncentiveListParams;
  status: IncentiveStatus;
  setPage: (value: number) => void;
  setPageSize: (value: number) => void;
  setIncentiveStatus: (value: string) => void;
  setPayrollSearch: (value: string) => void;
  setSearch: (value: string) => void;
}

export const useIncentiveListStore = create<IncentiveList>()(
  devtools(
    immer((set) => ({
      params: {
        page: '1',
        pageSize: '9',
        status: INCENTIVE_STATUS.ACTIVE,
      },
      status: 'Active',
      setPage: (value) =>
        set(
          (state) => {
            state.params.page = value.toString();
          },
          false,
          'setPage'
        ),
      setPageSize: (value: number) =>
        set(
          (state) => {
            state.params.pageSize = value.toString();
            state.params.page = '1';
          },
          false,
          'setPageSize'
        ),
      setIncentiveStatus(value) {
        set(
          (state) => {
            if (value !== 'All') {
              state.params.status = value;
            } else {
              delete state.params.status;
            }
          },
          false,
          'incentive-status'
        );
      },
      setPayrollSearch: (value: string) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.payrollLocation = value;
            } else {
              delete state.params.payrollLocation;
            }
          },
          false,
          'set-payroll-search'
        ),
      setSearch: (value) =>
        set(
          (state) => {
            if (value.length > 0) {
              state.params.search = value;
            } else {
              delete state.params.search;
            }
          },
          false,
          'set-calgroup-search'
        ),
    }))
  )
);
