import { useMutation } from '@tanstack/react-query';
import { notify } from 'components/Notification/useNotification';
import { queryClient } from 'services/api/react-query';
import {
  UpdateIncentiveReqBody,
  ReactivateIncentiveReqBody,
  AddIncentiveReqBody,
} from '../types/types';
import { addIncentive, inactiveIncentive, reactivateIncentive } from './api';

export const useInactiveIncentive = (id: string) => {
  return useMutation({
    mutationFn: (body: UpdateIncentiveReqBody) =>
      inactiveIncentive.put(id, body),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: ['incentive-list'],
      });
    },
  });
};

export const useReactivateIncentive = (id: string) => {
  return useMutation({
    mutationFn: (body: ReactivateIncentiveReqBody) =>
      reactivateIncentive.put(body, id),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: ['update_incentive', id],
        type: 'active',
      });
    },
  });
};

export const useAddIncentive = () => {
  return useMutation({
    mutationFn: (body: AddIncentiveReqBody) => addIncentive.post(body),
    onSuccess: async (response) => {
      notify.success({
        message: response.message,
      });
      await queryClient.refetchQueries({
        queryKey: ['incentive-list'],
      });
    },
  });
};
