import { SalaryGradeCard } from '../Cards/SalaryGradeCard';
import { Alert } from '@uss/react-core';

export const SalaryGrade = () => {
  return (
    <>
      <div className="w-fit">
        <Alert variant="info" message="Effective Date: Aug18, 2021" />
      </div>
      <div className="grid grid-cols-3 my-2 gap-4">
        <SalaryGradeCard />
        <SalaryGradeCard />
        <SalaryGradeCard />
        <SalaryGradeCard />
        <SalaryGradeCard />
        <SalaryGradeCard />
      </div>
    </>
  );
};
