import { DatePicker, Select } from '@uss/react-core';
export const ContractRateFilter = () => {
  return (
    <div className="flex md:flex-row flex-col items-center w-full md:gap-4 mb-4">
      <Select
        className="flex-1 min-w-0 w-full"
        label="Payroll Location"
        placeholder="Select"
      />
      <Select
        className="flex-1 min-w-0 w-full"
        label="Bargaining Unit"
        placeholder="Select"
      />

      <Select
        className="flex-1 min-w-0 w-full"
        label="Status"
        placeholder="Select"
      />
      <div className="flex-1 min-w-0 w-full">
        <DatePicker label="Effective Date"></DatePicker>
      </div>
    </div>
  );
};
