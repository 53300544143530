import { PayPeriodStatus } from 'features/pay-periods/types';
import { SortDirection } from 'types/common';
import { SortOptions } from './filters';
import { EmpClasses, TimecardStatusCode } from './timecards';

export enum CrewEmployeeType {
  DEFAULT = 'default',
  TEMP = 'temp',
}

/**
 * GET request - /crews/{crewKey}/timecards
 *
 * This is used to get a list of all available timecards for a given crew
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/crew-time-card-controller/getTimecards
 */
export interface CrewTimecardList {
  items: CrewTimeCard[];
  page: number;
  pageSize: number;
  totalCount: number;
  crewKey: string;
  crewId: string;
  crewDescription: string;
  payPeriodStatus: PayPeriodStatus;
  approvedTimeCardExists: boolean;
}

/**
 * Options for GET request - /crews/{crewKey}/timecards
 *
 * @link https://cappsq.psc.uss.com/SBWM-DOCUMENT/swagger-ui/index.html?urls.primaryName=sbwm-schedulepay#/crew-time-card-controller/getTimecards
 */
export interface CrewTimecardListOptions {
  week: string;
  crewId?: string;
  employeeType?: CrewEmployeeType;
  search?: string;
  sortBy?: SortOptions;
  sortType?: SortDirection;
  page?: string;
  pageSize?: string;
  status?: TimecardStatusCode;
  empClassFilter?: EmpClasses;
}

/**
 * Individual Crew Timecard Record
 */

export interface CrewTimeCard {
  id: string;
  name: string;
  ussId: number;
  employeeType: CrewEmployeeType;
  empclass: string;
  empclassDesc: string;
  empclassDescAbbr: string;
  elapsedTime: string | null;
  status: TimecardStatusCode | null;
  totalHours: CrewTimeCardTotalHours;
  payPeriodStatus?: PayPeriodStatus;
  currentPayPeriodStatus?: PayPeriodStatus;
  payrollNameId: string;
  payrollNameIdAbbr: string;
}

/**
 * Properites for the CrewTimecard Total Hours
 *
 * All fields will return null of there is nothing set
 */
interface CrewTimeCardTotalHours {
  scheduled: number | null;
  worked: number | null;
  approved: number | null;
  overtime: number | null;
  paidHoursNotWorked: number | null;
}
