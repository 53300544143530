import { AxiosError } from 'axios';
import { OrgSecurityRecord } from 'features/org/types';
import { RoleCodes } from 'features/roles/types';

export type WeekStart = 0 | 6;
export interface PaginationResponse {
  page: number;
  pageSize: number;
  totalCount: number;
}

export interface WMSAPIResponse<T> extends PaginationResponse {
  items: T[];
}

interface ApiErrorResponse {
  status: number;
  id: string;
  success: boolean;
  errors: ApiError[];
}

interface ApiError {
  keyField: string;
  status: string;
  errorCode: string;
  message: string;
  timeStamp: string;
}

export type WMSError = AxiosError<ApiErrorResponse>;

export interface ApiBasicStatusResponse {
  success: boolean;
  message: string;
  status?: number;
}
export interface ApiBlackoutStatusResponse {
  success: boolean;
  message: string;
  status?: number;
  items?: ApiErrorResponse[];
}

export interface MenuItem {
  title: string;
  roles: string[];
  path: string;
  external?: boolean;
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export enum SortBy {
  SECURITYCREATEDATE = 'securityCreateDate',
  ROLE = 'role',
  SORTORDER = 'sortOrder',
  FIRSTNAME = 'firstName',
  LASTNAME = 'lastName',
  USSID = 'ussId',
  JOBTITLE = 'jobTitle',
  EMPCLASS = 'empClass',
  SHIFTASSIGNMENT = 'shiftAssignment',
  CODE = 'code',
}

export type ZodErrorType = Record<string, string>;

export interface OrgSecurityList extends PaginationResponse {
  items: OrgSecurityRecord[];
}

export interface OrgSecurityOptions {
  role: RoleCodes;
  primary?: 'y';
  week?: string;
  sortBy?: SortBy;
  sortType?: SortDirection;
  page?: string;
  pageSize?: string;
  search?: string;
  retrieveFutureDatedRecords?: string;
}

export type UseCrewMembersOptions = Omit<OrgSecurityOptions, 'role'>;

export type UsePrimaryPayApproverOptions = Omit<
  OrgSecurityOptions,
  'role' | 'primary'
>;

export interface CommonPostResponse {
  success: boolean;
  message: string;
}
export enum YesNo {
  YES = 'y',
  NO = 'n',
}

export type ProcessStatus =
  | 'in_progress'
  | 'completed'
  | 'aborted'
  | 'error'
  | 'terminated';
