import { Checkbox, Table } from '@uss/react-components';
import React from 'react';
import { CrewEmployeeType, CrewTimeCard } from 'features/timecards/types';
import NoResults from 'components/NoResults/NoResults';
import { BsExclamationCircle, BsExclamationTriangle } from 'react-icons/bs';
import { useNonUnionStore } from 'features/timecards/hooks/useNonUnionStore';
import { PAY_PERIOD_STATUS, PayPeriodStatus } from 'features/pay-periods/types';
import CrewListingTableSkeleton from './Loaders/CrewListingTableSkeleton';
import isEmployeeSelected from 'features/timecards/utilities/checkEmployeeTimecardSelection';
import useUserRoles from 'hooks/useUserRoles';
import { ROLES } from 'features/roles/types';
import getFormattedCrewFilterStatus from 'features/timecards/utilities/getFormattedCrewFilterStatus';
import getNoPayEmployees from 'features/timecards/utilities/getNoPayEmployees';
import getHighApprovedHrsEmps from 'features/timecards/utilities/getHighApprovedHrsEmps';

interface EmployeeTableProps {
  allActive: boolean;
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingTimecards: boolean;
  employees: CrewTimeCard[];
  handleRowClick: (id: number, target: Element) => void;
  employeeErrors: string[];
  payPeriodStatus: PayPeriodStatus | undefined;
  rowSelect?: boolean;
  plantType?: string | null;
}

const EmployeeTimecardTable = ({
  allActive,
  handleOnChange,
  isLoadingTimecards,
  employees,
  handleRowClick,
  employeeErrors,
  payPeriodStatus,
  rowSelect = false,
  plantType,
}: EmployeeTableProps) => {
  const state = useNonUnionStore();
  //checking for scheduling(crew-listing) screen
  const feature = state.feature;
  const isSchedulingDetails = feature === 'scheduling';

  const { roles } = useUserRoles();
  const isScheduledPayViewer = roles.includes(ROLES.SCHEDULE_PAY_VIEWER);
  const getRowClasses = (id: string): string => {
    if (employeeErrors.includes(id)) {
      return 'border border-red-500 bg-white odd:bg-gray-100 h-14 hover:transparent ';
    } else if (isEmployeeSelected(state.selectedEmployees, id)) {
      return 'bg-blue-100 h-14 ';
    } else return 'bg-white odd:bg-gray-100 h-14 t hover:bg-blue-100';
  };

  const isMassApprovalEnabled =
    rowSelect &&
    employees.length > 0 &&
    !isScheduledPayViewer &&
    !!employees.find(
      (ctc) =>
        ctc.payPeriodStatus === PAY_PERIOD_STATUS.OPEN ||
        (isSchedulingDetails &&
          ctc.payPeriodStatus === PAY_PERIOD_STATUS.NOT_OPEN)
    );

  const noPayEmployees = getNoPayEmployees(employees);
  const highApprovedHrs = getHighApprovedHrsEmps(employees);

  const TableHeader = () => {
    return (
      <Table.Header>
        <Table.HeaderRow>
          {isMassApprovalEnabled && (
            <Table.HeaderCell scope="col" rowSpan={2} select>
              <Checkbox
                label="Checkbox All"
                name="Checkbox all"
                id="select-all"
                hideLabel
                checked={
                  allActive ||
                  (state.selectedEmployees.length === employees.length &&
                    employees.length !== 0)
                }
                onChange={handleOnChange}
              />
            </Table.HeaderCell>
          )}
          <Table.HeaderCell scope="col" className="min-w-[15%]" rowSpan={2}>
            <div className="flex flex-col">
              <span>Name</span>
              <span>USS ID</span>
            </div>
          </Table.HeaderCell>

          <Table.HeaderCell
            scope="col"
            className="pt-2 w-[70%] xl:w-[65%]"
            colSpan={6}
          >
            Total Hours
          </Table.HeaderCell>
          <Table.HeaderCell
            scope="col"
            className="w-[10%] xl:w-[15%]"
            rowSpan={2}
          >
            Status
          </Table.HeaderCell>
          {payPeriodStatus === 'open' &&
            (employeeErrors.length > 0 ||
              noPayEmployees.length > 0 ||
              highApprovedHrs.length > 0) && (
              <Table.HeaderCell
                scope="col"
                rowSpan={2}
                className="w-8"
              ></Table.HeaderCell>
            )}
        </Table.HeaderRow>

        <Table.HeaderRow>
          <Table.HeaderCell scope="col" className="pb-2 w-[10%]">
            Elapsed
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="pb-2 w-[10%]">
            Scheduled
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="pb-2 w-[10%]">
            Worked
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="pb-2 w-[10%]">
            Approved
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="pb-2 w-[10%]">
            Overtime
          </Table.HeaderCell>
          <Table.HeaderCell scope="col" className="pb-2 w-[20%] xl:w-[15%]">
            Paid Not Worked
          </Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>
    );
  };
  const TableBody = () => {
    return (
      <Table.Body>
        {employees.map((ctc) => {
          return (
            <Table.BodyRow
              key={ctc.ussId}
              selected={
                isEmployeeSelected(state.selectedEmployees, ctc.id) || allActive
              }
              className={getRowClasses(ctc.id)}
              onClick={(e) => {
                const target = e.target as Element;
                handleRowClick(ctc.ussId, target);
              }}
            >
              {isMassApprovalEnabled && (
                <Table.BodyCell>
                  {(plantType !== 'integrated_mill' ||
                    ctc.payPeriodStatus === PAY_PERIOD_STATUS.OPEN) && (
                    <Checkbox
                      label={ctc.id}
                      name={ctc.id}
                      id={ctc.id}
                      hideLabel
                      checked={
                        allActive ||
                        isEmployeeSelected(state.selectedEmployees, ctc.id)
                      }
                      onChange={handleOnChange}
                    />
                  )}
                </Table.BodyCell>
              )}
              <Table.BodyCell>
                <div className="flex flex-col py-1">
                  <p
                    className="cursor-pointer line-clamp-2"
                    data-testid="employee-name"
                  >
                    {ctc.name}
                  </p>
                  <div className="flex flex-col">
                    <p
                      className="line-clamp-1 text-xs"
                      data-testid="employee-ussId"
                    >
                      {`${ctc.ussId} - ${ctc.payrollNameIdAbbr} - ${ctc.empclassDescAbbr}`}
                    </p>
                    {ctc.employeeType === CrewEmployeeType.TEMP && (
                      <>
                        {/* <div className="bg-gray-300 w-px mx-2"></div> */}
                        <p
                          className="text-xs cursor-pointer"
                          data-testid="temp-employee"
                        >
                          Temp
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">{ctc.elapsedTime ?? '-'}</span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.totalHours.scheduled ?? '-'}
                </span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.totalHours.worked ?? '-'}
                </span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.totalHours.approved ?? '-'}
                </span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.totalHours.overtime ?? '-'}
                  {/*TODO once implementations is done from backend {ctc.totalHours.overtime} */}
                </span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.totalHours.paidHoursNotWorked ?? '-'}
                </span>
              </Table.BodyCell>
              <Table.BodyCell>
                <span className="line-clamp-2">
                  {ctc.status ? getFormattedCrewFilterStatus(ctc.status) : '-'}
                </span>
              </Table.BodyCell>
              {payPeriodStatus === 'open' &&
                (noPayEmployees.length > 0 ||
                  employeeErrors.length > 0 ||
                  highApprovedHrs.length > 0) && (
                  <Table.BodyCell>
                    {(noPayEmployees.includes(ctc) ||
                      highApprovedHrs.includes(ctc)) && (
                      <BsExclamationCircle
                        className="text-yellow-700"
                        size="1rem"
                        aria-label="Error"
                      ></BsExclamationCircle>
                    )}
                    {employeeErrors.includes(ctc.id) && (
                      <BsExclamationTriangle
                        color="red"
                        size="1rem"
                        aria-label="Error"
                      ></BsExclamationTriangle>
                    )}
                  </Table.BodyCell>
                )}
            </Table.BodyRow>
          );
        })}
      </Table.Body>
    );
  };
  const NoResultsFound = () => {
    return (
      <Table.Body>
        <Table.BodyRow>
          <Table.BodyCell colSpan={isMassApprovalEnabled ? 9 : 8}>
            <NoResults width={'300'} height={'300'} />
          </Table.BodyCell>
        </Table.BodyRow>
      </Table.Body>
    );
  };
  const TableContent = () => {
    return employees.length > 0 ? <TableBody /> : <NoResultsFound />;
  };
  return (
    <Table className="w-full">
      <TableHeader />
      {isLoadingTimecards ? (
        <CrewListingTableSkeleton
          numberOfRows={10}
          numberOfColumns={isMassApprovalEnabled ? 9 : 8}
        />
      ) : (
        <TableContent />
      )}
    </Table>
  );
};

export default EmployeeTimecardTable;
