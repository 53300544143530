import Loader from 'components/Skeleton/Loader';
import React from 'react';

const CrewListingCardSkeleton = () => {
  return (
    <div>
      {Array.from(Array(10)).map((num, index) => (
        <div key={index}>
          <CrewListingCard />
        </div>
      ))}
    </div>
  );
};

const CrewListingCard = () => {
  return (
    <>
      <hr className="ml-4 mr-4 border" />
      <div className="px-4 py-3 my-4">
        <div className="mb-4 py-2">
          <Loader rows={1} />
          <Loader rows={1} />
          <Loader rows={1} />
        </div>

        <div className="mb-4">
          <div className="text-sm text-gray-600 font-semibold">Total Hours</div>
          <div className="grid grid-cols-2 ">
            <div className="flex flex-col justify-start">
              <span className="text-xs text-gray-600 ">Scheduled</span>
              <span className=" pr-2">
                <Loader rows={1} />
              </span>
            </div>
            <div className="flex flex-col justify-start">
              <span className="text-xs text-gray-600 ">Worked</span>
              <span className=" pr-2">
                <Loader rows={1} />
              </span>
            </div>
            <div className="flex flex-col justify-start">
              <span className="text-xs text-gray-600 ">Approved</span>
              <span className=" pr-2">
                <Loader rows={1} />
              </span>
            </div>
            <div className="flex flex-col justify-start">
              <span className="text-xs text-gray-600 ">Paid Not Worked</span>
              <span className=" pr-2">
                <Loader rows={1} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CrewListingCardSkeleton;
