import {
  AutoSearch,
  Chip,
  ChipGroup,
  Select,
  SelectOptions,
} from '@uss/react-core';
import Page from 'components/Layouts/Page';
import IncentiveListing from 'features/Incentive/components/IncentiveListing/IncentiveListing';
import { useIncentiveListStore } from 'features/Incentive/state/incentive-list-store';
import { useOrgV2 } from 'features/org/apiV2/queries';
import { ROLES } from 'features/roles';
import useUserRoles from 'hooks/useUserRoles';
import { Key, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';
import { type Selection } from 'react-aria-components';

const IncentiveTypeFilter = ['All', 'Active', 'Inactive'];

export const IncentiveTable = () => {
  const { roles } = useUserRoles();
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedKeys, setSelectedKeys] = useState<Selection>(
    new Set(['Active'])
  );
  const [payLocation, setPayLocation] = useState('');

  const store = useIncentiveListStore();

  //location selection
  const { data: payrollLocationList, status: locationStatus } = useOrgV2({
    type: 'location',
  });

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    store.setSearch(value);
    // store.setAppCodeSearch(value);
  };

  const handlePayrollLocation = (val: Key) => {
    setPayLocation(val.toString());
    store.setPayrollSearch(val.toString());
  };

  const handleIncentiveChipSelection = (keys: Selection) => {
    const selectedValue = Array.from(keys)[0] ?? 'active';
    store.setIncentiveStatus(selectedValue.toString());
    setSelectedKeys(new Set([selectedValue]));
  };

  return (
    <Page
      breadcrumbs={[
        <NavLink key="ic-1" to="/incentives/incentive-table">
          Incentives
        </NavLink>,
      ]}
      heading={'Incentive Table'}
    >
      <Page.Section bg="gray">
        <div className="flex flex-col gap-3 lg:flex-row lg:items-center justify-between lg:gap-2">
          <div className="w-full text-sm md:w-2/6 flex-shrink-0">
            <AutoSearch
              id="search-incentive"
              aria-label="search-incentive"
              placeholder="Search Cal Group/Application Code"
              value={searchValue}
              onChange={(e) => {
                handleSearchChange(e);
              }}
            />
          </div>

          <Select
            items={payrollLocationList?.items}
            onSelectionChange={(e) => handlePayrollLocation(e)}
            selectedKey={payLocation}
            placeholder="Payroll Location"
            isDisabled={locationStatus === 'pending'}
            className="w-full border-gray-400"
          >
            {(item) => (
              <SelectOptions id={item.id}>
                {item.name} - {item.description}
              </SelectOptions>
            )}
          </Select>
          <div className="lg:h-8 lg:border-r-2 border-gray-300 mx-2"></div>

          <div className="sm:flex sm:flex-row w-full ">
            <div className="flex flex-row">
              <ChipGroup
                selectionMode="single"
                selectedKeys={selectedKeys}
                aria-label="IncentiveType"
                onSelectionChange={(keys) => handleIncentiveChipSelection(keys)}
              >
                {IncentiveTypeFilter.map((item) => {
                  return (
                    <Chip key={item} size="large" id={item}>
                      {item}
                    </Chip>
                  );
                })}
              </ChipGroup>
            </div>
          </div>
          {!(
            roles.includes(ROLES.INCENTIVE_CLERK) ||
            roles.includes(ROLES.PI_ADMIN)
          ) && (
            <div className="flex lg:justify-center w-full ">
              <NavLink
                className="flex flex-row justify-center items-center"
                to={'/incentives/incentive-table/add-calc-group'}
              >
                <div className="text-primary-400 font-semibold flex items-center mx-2">
                  <BsPlus size="2rem" />
                  <span>Add Cal group</span>
                </div>
              </NavLink>
            </div>
          )}
        </div>
      </Page.Section>
      <Page.Section>
        <IncentiveListing />
      </Page.Section>
    </Page>
  );
};
