import {
  Button,
  DatePicker,
  IconButton,
  Select,
  TextField,
} from '@uss/react-core';
import Page from 'components/Layouts/Page';
import LabeledText from 'components/Typography/LabeledText';
import { useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

export const EditContractRate = () => {
  const [addNewRate, setAddNewRate] = useState(false);

  return (
    <Page
      breadcrumbs={[
        <NavLink key="pc-1" to="/">
          Positions
        </NavLink>,
        <NavLink key="pc-1" to="/position/contract-rate">
          Contract Rates
        </NavLink>,
      ]}
      heading={'Edit Contract Rates'}
    >
      <Page.Section>
        <div className="sm:grid sm:grid-cols-2  text-wrap md:flex md:flex-row md:justify-start justify-between gap-2 bg-white pt-4 sm:bg-white rounded-md border border-blue-600 mb-2 pb-4 px-3">
          <div className="flex flex-row justify-between mb-2">
            <LabeledText label="Payroll Loc">A34</LabeledText>
            <span className=" md:border-r border-gray-200 md:ml-14 md:mr-4" />
            <LabeledText label="Bargaining Unit">01</LabeledText>
            <span className="md:border-r border-gray-200 md:ml-14 md:mr-2" />
          </div>
          <LabeledText label="Effective Date">-</LabeledText>
        </div>
        <div className="mb-4" />

        <div className="bg-white pt-4 sm:bg-white rounded-md mb-2 pb-4 px-3">
          <div className=" mt-2 md:w-3/4 mx-auto justify-center">
            <span className="font-semibold">Labor Grade: 01</span>
            <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
              <div>
                <TextField
                  label="Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 02</span>
            <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
              <div>
                <TextField
                  label="Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 03</span>
            <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
              <div>
                <TextField
                  label="Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 04</span>
            <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
              <div>
                <TextField
                  label="Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="border-t-2 my-4" />

            <span className="font-semibold">Labor Grade: 05</span>
            <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
              <div>
                <TextField
                  label="Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Standard Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
              <div>
                <TextField
                  label="Starter Converted Base Rate"
                  type="number"
                  placeholder="Enter number"
                />
              </div>
            </div>
            <div className="border-t-2 my-4" />

            {addNewRate && (
              <>
                <div className="w-1/4 pr-5">
                  <Select label="Labor Grade" placeholder="Select" />
                </div>
                <div className="flex flex-col md:flex-row text-sm w-full gap-4 mt-4">
                  <div className="w-1/2">
                    <TextField
                      label="Standard Rate"
                      type="number"
                      placeholder="Enter number"
                    />
                  </div>
                  <div className="w-1/2">
                    <TextField
                      label="Converted Base Rate"
                      type="number"
                      placeholder="Enter number"
                    />
                  </div>
                  <div className="w-1/2">
                    <TextField
                      label="Starter Standard Rate"
                      type="number"
                      placeholder="Enter number"
                    />
                  </div>
                  <div className="w-1/2">
                    <TextField
                      label="Starter Converted Standard Rate"
                      type="number"
                      placeholder="Enter number"
                    />
                  </div>
                </div>
                <div className="border-t-2 my-4" />
              </>
            )}

            <div className="flex flex-row justify-between items-center w-auto">
              <div className="w-1/2 pr-6">
                <DatePicker label="Effective Date"></DatePicker>
              </div>
              <div>
                <IconButton
                  onPressChange={() => setAddNewRate(true)}
                  className="flex flex-row items-center w-full justify-end text-primary-400"
                >
                  <BsPlus size="2rem" />
                  <span>Add Rate</span>
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-start mt-2">
          <Button variant="filled">Save</Button>
          <Button variant="outline">Cancel</Button>
        </div>
      </Page.Section>
    </Page>
  );
};
