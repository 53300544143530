import Page from 'components/Layouts/Page';
import { IncentiveForm } from 'features/Incentive/components/Forms/IncentiveForm';

import { NavLink } from 'react-router-dom';

export const AddCalcGroup = () => {
  return (
    <Page
      breadcrumbs={[
        <NavLink key="ac-1" to="/incentives/incentive-table">
          Incentive
        </NavLink>,
        <NavLink key="ac-2" to="/incentives/incentive-table">
          Incentive Table
        </NavLink>,
      ]}
      heading={'Add Calculation Group'}
    >
      <Page.Section>
        <IncentiveForm />
      </Page.Section>
    </Page>
  );
};
