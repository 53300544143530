import Home from './views/home/Home';
import CrewDetails from 'views/admin/crew-maintenance/crew/[crewId]';

import { PayPulloff } from 'views/admin/pay-pulloff/PayPulloff';
import { UnapprovedCrewDetails } from 'views/admin/pay-pulloff/unapproved-crews/[crewId]';
import PayCycles from 'views/reference-links/paycycles';
import NotFound from 'views/404';
import { Navigate, NonIndexRouteObject as RouteObject } from 'react-router-dom';
import ApplicationError from 'views/ApplicationError';
import { RolesType } from 'features/roles';
import { PAGE_ROLES } from 'roles';
import HelpfulHintsAll from 'views/home/HelpfulHintsAll';
import { HomeContent } from 'views/home/HomeContent';

//Crew Maintenance - Crew
import Crew from 'views/admin/crew-maintenance/crew';

// Crew Maintenance - Employee
import Employee from 'views/admin/crew-maintenance/employee';
import EmployeeDetails from 'views/admin/crew-maintenance/employee/[ussId]';

//Security
import SecurityHome from 'views/admin/security';
import Security from 'views/admin/security/Security';
import AssignSecurity from 'views/admin/security/assign-security/AssignSecurity';

import CrewPtoHome from 'views/paid-time-off/crew-pto';
import RequestPTO from 'views/my-pto/request-pto';
import MyPto from 'views/my-pto';
import PTOOverview from 'views/paid-time-off';
import EmployeesV2 from 'views/paid-time-off/employee-search/employees';
import EmployeePtoV2 from 'views/paid-time-off/employee-search/[ussId]';
import AddPTO from 'views/paid-time-off/employee-search/[ussId]/add-pto';

//sell-back pto
import SellBackPTO from 'views/my-pto/sell-back-pto/sellBackPTO';

//Paycode
import CreatePaycode from 'views/admin/paycodes/create';
import PaycodeHome from 'views/admin/paycodes';
import Paycode from 'views/admin/paycodes/paycode';
import PaycodeDetails from 'views/admin/paycodes/[paycodeId]/index';

//Org Maintenance
import OrgHome from 'views/admin/org';
import Divisions from 'views/admin/org/divisions';
import Division from 'views/admin/org/[divisionId]/division-details';
import Departments from 'views/admin/org/[divisionId]/departments';
import Department from 'views/admin/org/[departmentId]/department-details';
import Crews from 'views/admin/org/[departmentId]/crews';
import AddDepartmentCode from 'views/admin/org/create/[divisionId]/department';
import AddDivisionCode from 'views/admin/org/create/[locationId]/division';

//sell-back pto

import PayCrewsListing from 'views/non-union-pay/overview';
import PayDetails from 'views/non-union-pay/overview/employee/[ussId]';
import SchedulingDetails from 'views/non-union-scheduling/crew-listing/employee/[ussId]';
import CopyEmployeeSchedule from 'views/non-union-scheduling/crew-listing/employee/[ussId]/copy-schedule';
import SchedulingCrewsListing from 'views/non-union-scheduling/crew-listing';
import MySchedule from 'views/non-union-scheduling/my-schedule/[ussID]';
import CopyMySchedule from 'views/non-union-scheduling/my-schedule/[ussID]/copy-schedule';
import MassPayEdit from 'views/non-union-pay/overview/mass-edit/employee/[ussId]';
import MassSchedulingEdit from 'views/non-union-scheduling/crew-listing/mass-edit/employee/[ussId]';
import AdminOverview from 'views/admin';
import PayOverview from 'views/pay';
import SchedulingOverview from 'views/scheduling';
import AreaManagerView from 'views/probationary-tracking/area-manager-view/employees';
import ReviewerView from 'views/probationary-tracking/reviewer-view/employees';
import ReviewerEmpDetails from 'views/probationary-tracking/reviewer-view/employees/[ussId]/index';
import AreaManagerEmpDetails from 'views/probationary-tracking/area-manager-view/employees/[ussId]';
import ReviewerReview from 'views/probationary-tracking/reviewer-view/employees/[ussId]/performance-review/[id]';
import AreaManagerReview from 'views/probationary-tracking/area-manager-view/employees/[ussId]/performance-review/[id]';
import HrLrView from 'views/probationary-tracking/hr-lr-view/employees';
import HrLrEmpDetails from 'views/probationary-tracking/hr-lr-view/employees/[ussId]/index';
import AreaManagerHelp from 'views/probationary-tracking/area-manager-view/employees/[ussId]/performance-review/[id]/help';
import ReviewerHelp from 'views/probationary-tracking/reviewer-view/employees/[ussId]/performance-review/[id]/help';
import HrLrHelp from 'views/probationary-tracking/hr-lr-view/employees/[ussId]/performance-review/[id]/help';
import HrLrReview from 'views/probationary-tracking/hr-lr-view/employees/[ussId]/performance-review/[id]';
import BargainingUnitTotal from 'views/probationary-tracking/bargaining-unit-total';
import EmployeeEmpDetails from 'views/probationary-tracking/employee-acknowledgement/[ussId]/index';
import EmployeeReview from 'views/probationary-tracking/employee-acknowledgement/[ussId]/performance-review/[id]';
import EmployeeHelp from 'views/probationary-tracking/employee-acknowledgement/[ussId]/performance-review/[id]/help';
import MyPtoRecord from 'views/my-pto/[ptoId]';
import EmployeePtoRecord from 'views/paid-time-off/employee-search/[ussId]/[ptoId]';
import { Mileage } from 'views/my-mileage/mileage';
import MileageApproval from 'views/pay/mileage-approval/mileage-approval';
import { AddCrewLink } from 'views/admin/crew-maintenance/crew/add-crew';
import PtoRequests from 'views/paid-time-off/pto-requests';
import { HolidayList } from 'views/reference-links/holiday-list';
import { ModelDetail } from 'views/scheduling/modeling/model-detail';
import { Modeling } from 'views/scheduling/modeling/modeling';
import { EditModel } from 'views/scheduling/modeling/model-edit';
import { IncentiveTable } from 'views/incentive/incentive-table';
import { IncentiveDetail } from 'views/incentive/incentive-detail';
import { AddCalcGroup } from 'views/incentive/add-calc-group';

//Table Maintenance
import TableMaintenance from 'views/admin/payroll-table-maintenance';
import { TableCategory } from 'views/admin/payroll-table-maintenance/category/[id]';
import { CodeEditForm } from 'views/admin/payroll-table-maintenance/category/[id]/edit';
import { CodeInactivateForm } from 'views/admin/payroll-table-maintenance/category/[id]/inactivate';
import { PositionCode } from 'views/position/position-code';
import { AddPositionCode } from 'views/position/add-position-code';
import { PositionCodeDetail } from 'views/position/position-code-detail';
import { EditContractRate } from 'features/Position/Components/ContractRate/EditContractRate';
import { ContractRateHistory } from 'features/Position/Components/ContractRate/ContractRateHistory';
import { HistoryDetail } from 'features/Position/Components/ContractRate/HistoryDetail';
import { ContractRate } from 'views/contract-rate/contract-rate';
import { VicingRate } from 'views/vicing-rate/vicing-rate';

interface RoutesObject extends RouteObject {
  permissions?: RolesType[];
  children?: RoutesObject[];
}

export const routes: RoutesObject[] = [
  {
    path: '',
    element: <Home />,
    children: [
      {
        path: '',
        element: <HomeContent />,
      },
      {
        path: 'helpful-hints',
        element: <HelpfulHintsAll />,
      },
    ],
  },
  {
    path: 'login',
    element: <Navigate to="/" replace />,
  },
  {
    path: 'admin',
    element: <AdminOverview />,
    permissions: PAGE_ROLES.ADMIN,
  },
  {
    path: 'pay',
    element: <PayOverview />,
    permissions: PAGE_ROLES.NON_UNION_PAY,
  },
  {
    path: 'scheduling',
    element: <SchedulingOverview />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_CREW_LISTING,
  },
  {
    path: 'incentives/incentive-table',
    element: <IncentiveTable />,
    permissions: PAGE_ROLES.INCENTIVE_TABLE,
  },
  {
    path: 'incentives/incentive-table/:id',
    element: <IncentiveDetail />,
    permissions: PAGE_ROLES.INCENTIVE_TABLE,
  },
  {
    path: 'incentives/incentive-table/add-calc-group',
    element: <AddCalcGroup />,
    permissions: PAGE_ROLES.INCENTIVE_TABLE,
  },
  {
    path: 'position/position-codes',
    element: <PositionCode />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/position-codes/add-position-code',
    element: <AddPositionCode />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/position-codes/position-code-detail',
    element: <PositionCodeDetail />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/contract-rate',
    element: <ContractRate />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/contract-rate/edit',
    element: <EditContractRate />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/contract-rate/history',
    element: <ContractRateHistory />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/contract-rate/history/history-detail',
    element: <HistoryDetail />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'position/vicing-rate',
    element: <VicingRate />,
    permissions: PAGE_ROLES.POSITION,
  },
  {
    path: 'admin/paycodes',
    element: <PaycodeHome />,
    permissions: PAGE_ROLES.PAY_CODE_MAINTENANCE,
    children: [
      {
        path: '',
        element: <Paycode />,
        permissions: PAGE_ROLES.PAY_CODE_MAINTENANCE,
      },
      {
        path: 'create',
        element: <CreatePaycode />,
        permissions: PAGE_ROLES.PAY_CODE_MAINTENANCE,
      },
      {
        path: ':id',
        element: <PaycodeDetails />,
        permissions: PAGE_ROLES.PAY_CODE_MAINTENANCE,
      },
    ],
  },
  {
    path: 'admin/crew-maintenance',
    element: <Employee />,
    permissions: PAGE_ROLES.CREW_MAINTENANCE,
  },
  {
    path: 'admin/crew-maintenance/:employeeId',
    element: <EmployeeDetails />,
    permissions: PAGE_ROLES.CREW_MAINTENANCE,
  },
  {
    path: 'admin/crew-maintenance/crew',
    element: <Crew />,
    permissions: PAGE_ROLES.CREW_MAINTENANCE,
  },
  {
    path: 'admin/crew-maintenance/crew/add-crew',
    element: <AddCrewLink />,
    permissions: PAGE_ROLES.ADD_CREW,
  },
  {
    path: 'admin/crew-maintenance/crew/:crewId',
    element: <CrewDetails />,
    permissions: PAGE_ROLES.CREW_MAINTENANCE,
  },
  {
    path: 'admin/org-structure',
    element: <OrgHome />,
    permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
    children: [
      {
        path: 'divisions',
        element: <Divisions />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: '',
        element: <Navigate to="divisions" />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: 'create/:parentOrgId/division',
        element: <AddDivisionCode />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: ':id/divisions-details',
        element: <Division />,

        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: 'create/:parentOrgId/department',
        element: <AddDepartmentCode />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: ':id/departments',
        element: <Departments />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: ':id/departments-details',
        element: <Department />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
      {
        path: ':id/crews',
        element: <Crews />,
        permissions: PAGE_ROLES.ORG_STRUCTURE_DIVISIONS,
      },
    ],
  },

  {
    path: 'admin/security',
    element: <SecurityHome />,
    permissions: PAGE_ROLES.SECURITY,
    children: [
      {
        path: '',
        element: <Security />,
        permissions: PAGE_ROLES.SECURITY,
      },
      {
        path: 'assign-security/:id',
        element: <AssignSecurity />,
        permissions: PAGE_ROLES.SECURITY,
      },
    ],
  },
  {
    path: 'admin/payroll-table-maintenance',
    element: <TableMaintenance />,
    permissions: PAGE_ROLES.PAYROLL_TABLE_MAINTENANCE,
  },
  {
    path: 'admin/payroll-table-maintenance/category/:id',
    element: <TableCategory />,
    permissions: PAGE_ROLES.PAYROLL_TABLE_MAINTENANCE,
  },
  {
    path: 'admin/payroll-table-maintenance/category/:id/edit',
    element: <CodeEditForm />,
    permissions: PAGE_ROLES.MODELING,
  },
  {
    path: 'admin/payroll-table-maintenance/category/:id/inactivate',
    element: <CodeInactivateForm />,
    permissions: PAGE_ROLES.MODELING,
  },

  {
    path: 'reference-links/pay-cycles',
    element: <PayCycles />,
    permissions: PAGE_ROLES.PAY_CYCLES,
  },
  {
    path: 'reference-links/holiday-list',
    element: <HolidayList />,
    permissions: PAGE_ROLES.HOLIDAY_LIST,
  },

  {
    path: 'pay/pay-pulloff/*',
    element: <PayPulloff />,
    permissions: PAGE_ROLES.PAY_PULLOFF,
  },
  {
    path: 'pay/pay-pulloff/unapproved-crews/:crewId',
    element: <UnapprovedCrewDetails />,
    permissions: PAGE_ROLES.PAY_PULLOFF,
  },
  {
    path: 'pay/mileage-approval',
    element: <MileageApproval />,
    permissions: PAGE_ROLES.MILEAGE_APPROVAL,
  },

  {
    path: 'my-pto-nu',
    element: <MyPto />,
    permissions: PAGE_ROLES.MY_PTO,
  },
  {
    path: 'my-pto-nu/sell-back-pto',
    element: <SellBackPTO />,
    permissions: PAGE_ROLES.MY_PTO,
  },
  {
    path: 'my-pto-nu/sell-back-pto',
    element: <SellBackPTO />,
    permissions: PAGE_ROLES.MY_PTO,
  },
  {
    path: 'paid-time-off/employees',
    element: <EmployeesV2 />,
    permissions: PAGE_ROLES.SEARCH_EMP_PTO,
  },
  {
    path: 'paid-time-off/employees/:id',
    element: <EmployeePtoV2 />,
    permissions: PAGE_ROLES.SEARCH_EMP_PTO,
  },
  {
    path: 'paid-time-off/employees/:id/add-pto',
    element: <AddPTO />,
    permissions: PAGE_ROLES.MY_PTO_V2,
  },
  {
    path: 'paid-time-off/employees/:id/:ptoId',
    element: <EmployeePtoRecord />,
    permissions: PAGE_ROLES.SEARCH_EMP_PTO,
  },
  {
    path: 'paid-time-off/requests',
    element: <PtoRequests />,
    // element: <PtoRequests />,

    permissions: PAGE_ROLES.PTO_REQUESTS,
  },
  {
    path: 'my-pto-nu/request-pto',
    element: <RequestPTO />,
    permissions: PAGE_ROLES.MY_PTO,
  },

  {
    path: 'my-pto-nu/:ptoId',
    element: <MyPtoRecord />,
    permissions: PAGE_ROLES.MY_PTO,
  },
  {
    path: 'paid-time-off/',
    element: <PTOOverview />,
    permissions: PAGE_ROLES.PTO_VACATION,
  },

  {
    path: 'paid-time-off/crew-pto',
    element: <CrewPtoHome />,
    permissions: PAGE_ROLES.CREW_PTO,
  },

  {
    path: 'application-error',
    element: <ApplicationError />,
  },
  {
    path: 'logout',
    element: <Home />,
  },
  //Non Union Pay
  {
    path: 'non-union-pay/overview',
    element: <PayCrewsListing />,
    permissions: PAGE_ROLES.NON_UNION_PAY,
  },
  {
    path: 'non-union-pay/employee/:ussId',
    element: <PayDetails />,
    permissions: PAGE_ROLES.NON_UNION_PAY,
  },
  {
    path: 'non-union-pay/mass-edit/employee/:ussId',
    element: <MassPayEdit />,
    permissions: PAGE_ROLES.NON_UNION_PAY_MASS_EDIT,
  },
  //Non Union Scheduling
  {
    path: 'non-union-scheduling/crew-listing',
    element: <SchedulingCrewsListing />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_CREW_LISTING,
  },
  {
    path: 'non-union-scheduling/employee/:ussId/copy-schedule',
    element: <CopyEmployeeSchedule />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_EMP_DETAILS,
  },
  {
    path: 'non-union-scheduling/employee/:ussId',
    element: <SchedulingDetails />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_EMP_DETAILS,
  },
  {
    path: 'non-union-scheduling/modeling',
    element: <Modeling />,
    permissions: PAGE_ROLES.MODELING,
  },
  {
    path: 'non-union-scheduling/modeling/:id',
    element: <ModelDetail />,
    permissions: PAGE_ROLES.MODELING,
  },
  {
    path: 'non-union-scheduling/modeling/:id/edit-model',
    element: <EditModel />,
    permissions: PAGE_ROLES.MODELING,
  },
  {
    path: 'my-schedule-nu/:ussId/copy-schedule',
    element: <CopyMySchedule />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_MY_SCHEDULE,
  },
  {
    path: 'my-schedule-nu/:ussId',
    element: <MySchedule />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_MY_SCHEDULE,
  },
  {
    path: 'non-union-scheduling/mass-edit/employee/:ussId',
    element: <MassSchedulingEdit />,
    permissions: PAGE_ROLES.NON_UNION_SCHED_MASS_EDIT,
  },
  //Probationary Employees
  /**Area Manager**/
  {
    path: 'probationary-tracking/area-manager-view/employees',
    element: <AreaManagerView />,
    permissions: PAGE_ROLES.PROB_EMP_AREA_MANAGER_VIEW,
  },
  {
    path: 'probationary-tracking/area-manager-view/employees/:ussId',
    element: <AreaManagerEmpDetails />,
    permissions: PAGE_ROLES.PROB_EMP_AREA_MANAGER_VIEW,
  },
  {
    path: 'probationary-tracking/area-manager-view/employees/:ussId/performance-review/:id',
    element: <AreaManagerReview />,
    permissions: PAGE_ROLES.PROB_EMP_AREA_MANAGER_VIEW,
  },
  {
    path: 'probationary-tracking/area-manager-view/employees/:ussId/performance-review/:id/help',
    element: <AreaManagerHelp />,
    permissions: PAGE_ROLES.PROB_EMP_AREA_MANAGER_VIEW,
  },
  /**Reviewer**/
  {
    path: 'probationary-tracking/reviewer-view/employees',
    element: <ReviewerView />,
    permissions: PAGE_ROLES.HR_LR,
  },
  {
    path: 'probationary-tracking/reviewer-view/employees/:ussId',
    element: <ReviewerEmpDetails />,
    permissions: PAGE_ROLES.PROB_EMP_REVIEWER_VIEW,
  },
  {
    path: 'probationary-tracking/reviewer-view/employees/:ussId/performance-review/:id',
    element: <ReviewerReview />,
    permissions: PAGE_ROLES.PROB_EMP_REVIEWER_VIEW,
  },
  {
    path: 'probationary-tracking/reviewer-view/employees/:ussId/performance-review/:id/help',
    element: <ReviewerHelp />,
    permissions: PAGE_ROLES.PROB_EMP_REVIEWER_VIEW,
  },
  /**HR_LR**/
  {
    path: 'probationary-tracking/hr-lr-view/employees',
    element: <HrLrView />,
    permissions: PAGE_ROLES.HR_LR,
  },
  {
    path: 'probationary-tracking/hr-lr-view/employees/:ussId',
    element: <HrLrEmpDetails />,
    permissions: PAGE_ROLES.PROB_EMP_HR_LR,
  },
  {
    path: 'probationary-tracking/hr-lr-view/employees/:ussId/performance-review/:id',
    element: <HrLrReview />,
    permissions: PAGE_ROLES.PROB_EMP_HR_LR,
  },
  {
    path: 'probationary-tracking/hr-lr-view/employees/:ussId/performance-review/:id/help',
    element: <HrLrHelp />,
    permissions: PAGE_ROLES.PROB_EMP_HR_LR,
  },
  /**Employee Acknowledgement**/
  {
    path: 'probationary-tracking/employee-acknowledgement/:ussId',
    element: <EmployeeEmpDetails />,
    permissions: PAGE_ROLES.PROB_EMP_EMPLOYEE_ACKNOWLEDGEMENT,
  },
  {
    path: 'probationary-tracking/employee-acknowledgement/:ussId/performance-review/:id',
    element: <EmployeeReview />,
    permissions: PAGE_ROLES.PROB_EMP_EMPLOYEE_ACKNOWLEDGEMENT,
  },
  {
    path: 'probationary-tracking/employee-acknowledgement/:ussId/performance-review/:id/help',
    element: <EmployeeHelp />,
    permissions: PAGE_ROLES.PROB_EMP_EMPLOYEE_ACKNOWLEDGEMENT,
  },
  /**Bargaining Unit Total**/
  {
    path: 'probationary-tracking/bargaining-unit-total',
    element: <BargainingUnitTotal />,
    permissions: PAGE_ROLES.PROB_EMP_BARGAINING_UNIT_TOTAL,
  },

  {
    path: 'my-mileage',
    element: <Mileage />,
    permissions: PAGE_ROLES.MY_MILEAGE,
  },
  {
    path: '*',
    element: <NotFound />,
  },
];
