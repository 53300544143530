import { Button } from '@uss/react-core';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import { useStickyStore } from './StickyWrapper';

interface StickyButtonProps {
  enabledText?: string;
  disabledText?: string;
}

const StickyButton = ({
  enabledText = 'Unpin Header',
  disabledText = 'Pin Header',
}: StickyButtonProps): JSX.Element => {
  const store = useStickyStore();
  const isSticky = store.isSticky;

  return (
    <>
      {isSticky ? (
        <Button
          variant="text"
          startIcon={<BsPinAngleFill />}
          onPress={() => store.setSticky(false)}
        >
          <span className="mr-2">{enabledText}</span>
        </Button>
      ) : (
        <Button
          variant="text"
          startIcon={<BsPinAngle />}
          onPress={() => store.setSticky(true)}
        >
          <span className="mr-2">{disabledText}</span>
        </Button>
      )}
    </>
  );
};

export default StickyButton;
